//React
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components';

//Components
import Header from "components/Header";
import Footer from 'components/Footer';

//Assets
import meercat404 from "assets/WrongUrl/meercat-404.png"
import { Text } from 'components/Text';
import Button from 'components/Join/Button';

function Index() {
    const navigate = useNavigate();

  return (
    <Fragment>
      <Header />
      <Wrapper>
        <MeercatLogo src={meercat404} />
        <Text size={'25px'} weight={800} color={'#4480F7'} lineHeight={'50px'} style={{textAlign: 'center'}}>
          페이지를 찾을 수 없습니다
          <Text size={'14px'} weight={400} lineHeight={'25px'} opacity={0.3}>원하시는 결과를 찾을 수 없습니다 <br /> 올바른 URL을 입력하였는지 확인하세요</Text>
        </Text>
        <Button 
          style={{maxWidth: 256, marginTop: 20}}
          type={'next'}
          isActive={true}
          description={'이전으로 돌아가기'}
          onClick={function(){navigate(-1)}}
        />
      </Wrapper>
      <Footer />
    </Fragment>
  )
}

export default Index

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;

`

const MeercatLogo = styled.img`
  width: 100%;
  max-width: 320px;
`

