import { useLayoutEffect, useReducer, useState } from 'react'

//utils
import Cookie from 'utils/Cookie/cookie'

//Types
import { LOGIN_COOKIE_NAMES } from 'types/cookieNames'
import { LOGIN_COOKIE_DEFAULT } from 'types/default'

//Api
import { authApi } from 'apis/api'

export interface Credentials {
  userId: string | null | undefined
  avatarId: string | null | undefined
  token: string | null | undefined,
}

export interface ValidityState {
  isLoading: boolean,
  isValid: boolean,
  isChecked: boolean
}

interface Action {
  type: string
}

enum ACTION_TYPE {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  VALID = 'VALID',
  INVALID ='INVALID',
}

enum ERROR {
  INVALID_USER_DATA = 'INVALID_USER_DATA',
  INVALID_AVATAR_DATA = 'INVALID_AVATAR_DATA'
}

const CREDENTIAL_RESET = {
  userId: null,
  avatarId: null,
  token: null
}

const CREDENTIAL_DEFAULT = {
  userId: Cookie.get(LOGIN_COOKIE_NAMES.ID) ?? null,
  avatarId: Cookie.get(LOGIN_COOKIE_NAMES.AVATAR) ?? null,
  token: Cookie.get(LOGIN_COOKIE_NAMES.TOKEN) ?? null
}

const VALIDITY_DEFAULT = {
  isLoading: false,
  isValid: false,
  isChecked: false
}

const reducer = function(state: ValidityState, action: Action){
  switch(action.type){
    case ACTION_TYPE.INITIAL:
      return {
        isLoading: false,
        isValid: false,
        isChecked: false
      }
    case ACTION_TYPE.LOADING:
      return {
        isLoading: true,
        isValid: state?.isValid,
        isChecked: false
      }
    case ACTION_TYPE.VALID:
      return {
        isLoading: false,
        isValid: true,
        isChecked: true
      }
    case ACTION_TYPE.INVALID:
      return {
        isLoading: false,
        isValid: false,
        isChecked: true
      }
    default :
      return {
        isLoading: false,
        isValid: false,
        isChecked: false
      }
  }
}

function useCredential() {
  const [credentials, setCredentials] = useState<Credentials>(CREDENTIAL_DEFAULT)
  const [userData, setUserData] = useState<any>(null)
  const [avatarData, setAvatarData] = useState<any>(null)
  const [validity, dispatcher] = useReducer(reducer, VALIDITY_DEFAULT)

  const updateCredential = function (args: Credentials) {
    const latestCredential = args
    let expires = new Date();
    expires.setTime(expires.getTime() + 1209600000)

    Cookie.set(LOGIN_COOKIE_NAMES.ID, latestCredential.userId, { ...LOGIN_COOKIE_DEFAULT, expires })
    Cookie.set(LOGIN_COOKIE_NAMES.AVATAR, latestCredential.avatarId, { ...LOGIN_COOKIE_DEFAULT, expires })
    Cookie.set(LOGIN_COOKIE_NAMES.TOKEN, latestCredential.token, { ...LOGIN_COOKIE_DEFAULT, expires })

    setCredentials(latestCredential)
  }

  const discardCredential = function () {

    Cookie.remove(LOGIN_COOKIE_NAMES.ID, { ...LOGIN_COOKIE_DEFAULT })
    Cookie.remove(LOGIN_COOKIE_NAMES.AVATAR, { ...LOGIN_COOKIE_DEFAULT })
    Cookie.remove(LOGIN_COOKIE_NAMES.TOKEN, { ...LOGIN_COOKIE_DEFAULT })

    dispatcher({type: ACTION_TYPE.INVALID})
    setUserData(null)
    setAvatarData(null)
    setCredentials(CREDENTIAL_RESET)
  }

  const checkUserDataFinality = async function(){
    try{
      const userId = credentials?.userId
      const token = credentials?.token

      const userData = await authApi.getIntegrateUserData(userId, token).promise() // 통합회원 존재정보 확인
      const email = userData?.data?.response?.email
      const phone = userData?.data?.response?.phone_number

      if(!userData || !email || !phone){

        throw "invalid userData"
      }

      return userData.data.response

    }catch(error){

      throw error
    }
  }

  const checkAvatarFinality = async function(){
    try{
      const avatarId = credentials?.avatarId
      const token = credentials?.token

      const avatarData = await authApi.getAvatarData(token).promise()
      const checkedAvatarId = avatarData?.data?.response?.[0]?.id
      const isServiceActive = avatarData?.data?.response?.[0]?.services?.includes('CAMPAIGN')

      if(checkedAvatarId !== avatarId || !isServiceActive){

        throw "invalid avatarData"
      }

      return avatarData.data.response[0]

    }catch(error){

      throw error
    }
  }

  const checkCredentialValidity = async function () {
    dispatcher({type: ACTION_TYPE.LOADING})

    try{
      const [userData, avatarData] = await Promise.all([checkUserDataFinality(), checkAvatarFinality()])

      setUserData(userData)
      setAvatarData(avatarData)
      dispatcher({type: ACTION_TYPE.VALID})

    }catch(error){

      discardCredential()
    }
  }

  useLayoutEffect(function(){

    if(credentials.userId && credentials.avatarId && credentials.token){
      
      checkCredentialValidity()
    }else{

      dispatcher({type: ACTION_TYPE.INVALID})
    }
  }, [credentials])

  return { 
    credentials, 
    validity, 
    userData, 
    avatarData, 
    updateCredential, 
    discardCredential, 
    checkCredentialValidity 
  }
}

export default useCredential