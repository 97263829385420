import MaintenancePopup, { MaintenancePopupProps } from "components/System/Popup/MaintenancePopup";
import { MutableRefObject, useMemo, useRef, useState } from "react";

export default function useMaintenancePopup() {
    const popupRef = useRef<HTMLDivElement | null>(null);
    const [popup, setPopup] = useState<Omit<MaintenancePopupProps, 'popupRef'> | null>(null);
    const [isPopupLoading, setIsPopupLoading] = useState(false);

    const handleMaintenancePopup = useMemo(function(){   // controller
        return {
          set: function(newMaintenancePopupProps: Omit<MaintenancePopupProps, 'popupRef'>){
            setIsPopupLoading(false)
            setPopup(newMaintenancePopupProps)
          },
          remove: function(){
            setPopup(null)
          },
          startLoading: function(){
            setIsPopupLoading(true)
          },
          endLoading: function(){
            setIsPopupLoading(false)
          }
        }
    }, [popup, setPopup, isPopupLoading, setIsPopupLoading, popupRef])

    const component = useMemo(function () {  // view
        return (
            <>
                {popup && 
                <MaintenancePopup
                    popupRef={popupRef}
                    startTime={popup.startTime}
                    endTime={popup.endTime}
                    button={popup.button}
                />}
            </>
        )
    }, [popup, isPopupLoading])

    return { handleMaintenancePopup, component }
}