import styled from "styled-components"

export const Flexbox = styled.div<{
  justify?: string;
  align?: string;
  gap?: string | number;
  flow?: string;
}>`
  display: flex;
  justify-content: ${props => props.justify ?? 'center'};
  align-items: ${props => props.align ?? 'center'};
  gap: ${props => props.gap ?? '0'}px;
  flex-flow: ${props => props.flow ?? ''};
`