const OFFER_PRICE = {
    MAX: 90000000,
    MIN: 200000
}

// 정산관련
const SERVICE_FEE = 200000
const TAX_FEE = 0.033

// 서비스 계약금 비율
const SERVICE_DOWNPAYMENT_RATE = 0.3

// 캠페인 제작

//기획안 메세지 최대 파일 크기
const CREATION_VERIFICATION = {
    MAXFILESIZE: 1024 * 1024 * 100 // 100MB
}

Object.freeze([SERVICE_FEE, CREATION_VERIFICATION, SERVICE_DOWNPAYMENT_RATE, OFFER_PRICE, TAX_FEE])

export { SERVICE_FEE, CREATION_VERIFICATION, SERVICE_DOWNPAYMENT_RATE, OFFER_PRICE, TAX_FEE }