//Hooks
import useMediaQuery from 'hook/useMediaQuery';

//Components
import Header from 'pages/LandingPage/components/Header';
import Footer from 'pages/LandingPage/components/Footer';
import Section01 from './components/Section01';
import Section02 from './components/Section02';
import Section03 from './components/Section03';
import Section04 from './components/Section04';
import Section05 from './components/Section05';

//Css
import { LandingDiv } from '../styles';

function Home() {

  const whetherDesktop = useMediaQuery(590);
  const whetherWindow1000 = useMediaQuery(1000);
  const whetherWindow800 = useMediaQuery(800);

  return (
    <LandingDiv>
      <Header whetherWindow800={whetherWindow800} />
      <Section01 whetherDesktop={whetherDesktop}/>
      <Section02 whetherDesktop={whetherDesktop} whetherWindow1000={whetherWindow1000}/>
      <Section03 whetherDesktop={whetherDesktop}/>
      <Section04 whetherDesktop={whetherDesktop}/>
      <Section05 whetherDesktop={whetherDesktop}/>
      <Footer whetherDesktop={whetherDesktop}/>
    </LandingDiv>
  );
  
}

export default Home;