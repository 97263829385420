//React
import styled from 'styled-components';

export const Text = ({ fontFamily, fontStyle, fontWeight, fontSize, lineHeight, textAlign, color }) => `
  font-family: ${fontFamily || 'Pretendard'};
  font-style: ${fontStyle || 'normal'};
  font-weight: ${fontWeight || '400'};
  font-size: ${fontSize || '18px'};
  line-height: ${lineHeight || '180%'};
  text-align: ${textAlign || ''};
  color: ${
    color==='white'?'#FFFFFF'
    :color==='opacity80'?'#333333'
    :color==='opacity60'?'#666666'
    :color==='opacity40'?'#999999'
    :color==='meercatBlue'?'#4480F7'
    :color
  };
`;

export const CenterDefault = ({ flexDirection, justifyContent, alignItems }) => `
  display: flex;
  flex-direction: ${flexDirection || 'column'};
  justify-content: ${justifyContent || 'center'};
  align-items: ${alignItems || 'center'};
`;

export const RowDefault = () => `
  display: flex;
  flex-direction: row;
`;

export const ColumnDefault = () => `
  display: flex;
  flex-direction: Column;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column' };
  justify-content: ${(props) => props.justifyContent || 'center' };
  align-items: ${(props) => props.alignItems || 'center' };
`;

export const Row = styled.div`
  ${RowDefault()}
  margin-top: ${(props) => props.top};
  margin-bottom: ${(props) => props.bottom};
  margin-left: ${(props) => props.left};
  margin-right: ${(props) => props.right};
`;

export const Column = styled.div`
  ${ColumnDefault()}
  margin-top: ${(props) => props.top};
  margin-bottom: ${(props) => props.bottom};
  margin-left: ${(props) => props.left};
  margin-right: ${(props) => props.right};
`;

export const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-top: ${(props) => props.top};
  margin-bottom: ${(props) => props.bottom};
  margin-left: ${(props) => props.left};
  margin-right: ${(props) => props.right};
  border-radius: ${(props) => props.borderRadius};
`;

export const FlexBox = styled.div`
  display: flex;
  flex: 1;
`;

export const SizedBox = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const Blue = styled.span`
  color: #4480F7;
`;

export const MainTitle = styled.span`
  margin-top: ${(props) => props.top};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row' };
  ${Text({
    fontWeight: '900',
    fontSize: '50px',
    lineHeight: '140%',
  })};
  text-align: ${(props) => props.center && 'center'};
  @media only screen and (max-width: 590px) {
    font-size: 25px;
  }
`;

export const Title = styled.span`
  margin-top: ${(props) => props.top};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row' };
  ${Text({
    fontWeight: '800',
    fontSize: '40px',
    lineHeight: '160%',
  })};
  text-align: ${(props) => props.center && 'center'};
  @media only screen and (max-width: 590px) {
    font-weight: 800;
    font-size: 20px;
    line-height: 160%;
  }
`;

export const SubTitle = styled.span`
  margin-top: ${(props) => props.top};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row' };
  ${Text({
    fontWeight: '800',
    fontSize: '34px',
    lineHeight: '160%',
  })};
  text-align: ${(props) => props.center && 'center'};

  @media only screen and (max-width: 590px) {
    font-size: 20px;
  }
`;

export const Subscription = styled.span`
  margin-top: ${(props) => props.top};
  ${RowDefault()};
  ${Text({
    fontSize: '24px',
    color: 'opacity60',
  })}
  text-align: ${(props) => props.center && 'center'};

  @media only screen and (max-width: 590px) {
    font-size: 15px;
  }
`;

export const LandingDiv = styled.div`
  width: 100%;
  height: 100%;
  ${CenterDefault({})}
  ${Text({})}
  overflow: hidden;
`;

export const ComponentDiv = styled.div`
  width: 100%;
  height: 100%;
  ${CenterDefault({})}
  position: ${(props) => props.whetherBackground && 'relative'};
  overflow: hidden;
`;

export const ComponentContainer = styled.div`
  width: 100%;
  max-width: 1130px;
  padding: 0 40px;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column' };
  justify-content: ${(props) => props.center? 'center': 'flex-start' };
  align-items: ${(props) => props.center? 'center': 'flex-start' };
  z-index: ${(props) => props.whetherBackground && '1'};

  @media only screen and (max-width: 590px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

export const RelativeDiv = styled.div`
  width: 100%;
`;

export const RelativeContainer = styled.div`
  padding-bottom: ${(props) => props.ratio};
  position: relative;
`;

export const RelativeComponent = styled.div`
  position: absolute;
  width: 100%; 
  height: 100%;
  ${CenterDefault({})}
  background: ${(props) => props.background};
`;

export const SubPageIconBox = styled.div`
  width: 224px;
  height: 48px;
  margin-bottom: 10px;
  border-radius: 24px;
  background: #FAFAFA;
  ${CenterDefault({
    flexDirection: 'row',
  })}
  ${Text({
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '180%',
    color: '#AFAFAF',
  })};

  @media only screen and (max-width: 590px) {
    width: 178px;
    height: 40px;
    margin-bottom: 15px;
    font-size: 16px;
  }
`;

export const SubPageIcon = styled.img`
  width: 24px; 
  margin-right: 7px;
  opacity: 0.3;

  @media only screen and (max-width: 590px) {
    width: 16px; 
    margin-right: 8px;
  }
`;