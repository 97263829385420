//React
import { useState } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";

//Assets
import { ReactComponent as MenuSvg } from "assets/LandingPageDev/Header/menu-icon.svg";
import { ReactComponent as CloseSvg } from "assets/LandingPageDev/Header/close-icon.svg";
import { ReactComponent as ToggleOn } from "assets/common/toggle_active_icon.svg";

//Components
import { Flexbox } from "components/Flexbox";
import { Text } from "components/Text";
import CreatorlyLogo from "components/System/Logo";

function Header() {
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [functionToggle, setFunctionToggle] = useState(false);
  const [functionDesktopToggle, setFunctionDesktopToggle] = useState(false);

  const handleLogoOnclick = function () {
    if (window.location.href === `${window.location.origin}`) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
    }
  };

  const handleMenuOnclick = function () {
    setToggle((current) => !current);
  };

  const handleFunctionToggleOnclick = function () {
    setFunctionToggle((current) => !current);
  };

  const handleMenuContentOnclick = function (content) {
    if (content === "로그인") {
      navigate("/login");
    } else if (content === "회원가입") {
      window.location.href = process.env.REACT_APP_MEERCAT_KAKAO;
    } else if (content === "회사 소개") {
      navigate("/team");
    } else if (content === "광고 관리") {
      navigate("/tutorial/proposal");
    } else if (content === "전자 계약") {
      navigate("/tutorial/contract");
    } else if (content === "안전 결제") {
      navigate("/tutorial/escrow");
    } else if (content === "분석 리포트") {
      navigate("/tutorial/analysis");
    } else if (content === "구독자 관리") {
      window.location.replace("https://fans.creator.ly");
    }
  };

  const handleFunctionHover = function () {
    setFunctionDesktopToggle(true);
  };

  const handleFunctionLeave = function () {
    setFunctionDesktopToggle(false);
  };

  return (
    <HeaderWrapper>
      <HeaderContent>
        <NavigationWrapper>
          <CreatorlyLogo
            style={{ marginRight: 19 }}
            onClick={handleLogoOnclick}
          />
          <MenuContentText
            size={"15px"}
            weight={500}
            color={"rgb(36, 41, 46, 1)"}
            onClick={function () {
              handleMenuContentOnclick("회사 소개");
            }}
          >
            회사 소개
          </MenuContentText>
          <MenuContentText
            size={"15px"}
            weight={500}
            color={"rgb(36, 41, 46, 1)"}
            onClick={function () {
              window.location.href = "https://biz.creator.ly";
            }}
          >
            광고주이신가요?
          </MenuContentText>
        </NavigationWrapper>
        <StartServiceWrapper>
          {/* <LoginWrapper
            onClick={function (e) {
              e.preventDefault();
              handleMenuContentOnclick("로그인");
            }}
          >
            <a href={`${window.location.origin}/login`}>로그인</a>
          </LoginWrapper> */}
          <JoinWrapper
            onClick={function (e) {
              e.preventDefault();
              handleMenuContentOnclick("회원가입");
            }}
          >
            <a href={process.env.REACT_APP_MEERCAT_KAKAO}>카카오톡으로 시작하기</a>
          </JoinWrapper>
        </StartServiceWrapper>
      </HeaderContent>
    </HeaderWrapper>
  );
}

export default Header;

const MenuContentText = styled(Text)`
  padding: 13px 29px;

  border-radius: 10px;

  text-align: center;
  cursor: pointer;

  position: relative;

  &:hover {
    font-weight: 700;
    background: rgba(230, 230, 230, 0.3);
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const Media800Up = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

const Media800Under = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;

const SubMenuWrapperDesktop = styled.div`
  width: 100%;
  min-height: 300px;

  padding-top: 10px;

  position: absolute;
  left: 0px;
  bottom: -300px;
  border-radius: 10px;

  display: flex;
  flex-flow: column nowrap;

  animation: SubMenu-appear 0.2s 1 ease;
  -webkit-animation: SubMenu-appear 0.2s 1 ease;

  @keyframes SubMenu-appear {
    0% {
      opacity: 0;
      transform: translate3d(0px, -5px, 0px);
    }
    100% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }
`;

const SubMenuDesktopText = styled(Text)`
  flex: 1;

  background: white;
  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  color: rgb(0, 0, 0, 0.5);
  cursor: pointer;

  &:hover {
    font-weight: 700;
    color: rgb(0, 0, 0, 0.8);
  }
`;

const MenuWrapper = styled.div`
  min-width: 100vw;

  padding: 22px 20px 66px 20px;

  background: white;
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  position: fixed;
  top: 70px;
  left: 0px;

  display: flex;
  flex-flow: column nowrap;
  gap: 24px;

  animation: Menu-appear 0.3s 1 ease;
  -webkit-animation: Menu-appear 0.3s 1 ease;

  @keyframes Menu-appear {
    0% {
      opacity: 0;
      transform: translate3d(0px, -10px, 0px);
    }
    100% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

const SubMenuWrapper = styled.div`
  width: 100%;

  padding-left: 24px;
  margin-top: 18px;
  margin-bottom: 16px;

  position: relative;

  display: flex;
  flex-flow: column nowrap;
  gap: 24px;

  animation: SubMenu-appear 0.2s 1 ease;
  -webkit-animation: SubMenu-appear 0.2s 1 ease;

  @keyframes SubMenu-appear {
    0% {
      opacity: 0;
      transform: translate3d(0px, -5px, 0px);
    }
    100% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }
`;

const HeaderWrapper = styled(Flexbox)`
  width: 100%;
  height: 80px;

  background: white;
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.03);

  position: fixed;
  top: 0;

  @media (max-width: 700px) {
    height: 70px;
  }

  transition: all 0.1s linear;

  z-index: 3;
`;

const HeaderContent = styled.div`
  width: 100%;
  max-width: 1130px;
  height: 100%;

  padding: 0px 40px;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    padding: 0px 20px;
  }
`;

const NavigationWrapper = styled(Flexbox)`
  gap: 15px;
`;

const StartServiceWrapper = styled(Flexbox)`
  gap: 20px;
`;

const LoginWrapper = styled(Flexbox)`
  padding: 12px 24px;

  border: 1px solid rgb(68, 128, 247, 0.1);
  border-radius: 10px;

  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: #0050ff;

  cursor: pointer;

  &:active {
    transform: scale(0.99);
  }

  transition: all 0.2s linear;

  @media (max-width: 900px) {
    display: none;
  }
`;

const JoinWrapper = styled(Flexbox)`
  padding: 12px 24px;

  background: #0050ff;
  border: 1px solid #0050ff;
  border-radius: 10px;

  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: white;

  cursor: pointer;

  &:active {
    transform: scale(0.99);
  }

  @media (max-width: 900px) {
    padding: 10px 20px;

    border-radius: 5px;

    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: white;
  }

  transition: all 0.2s linear;
`;

const ToggleSvg = styled(ToggleOn)`
  margin-left: 6px;
  margin-bottom: 2px;

  ${({ isActive }) =>
    !isActive &&
    css`
      transform: rotateX(180deg);
    `}

  transition: transform 0.2s linear;
`;
