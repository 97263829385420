import { createContext } from "react";
import { Credentials, ValidityState } from "modules/Credential/hooks/useCredential";

const CredentialContext = createContext<{
    credentials?: Credentials
    validity?: ValidityState
    userData?: any
    avatarData?: any
    updateCredential?: ( args:Credentials ) => void
    discardCredential?: () => void
}>({})

export default CredentialContext