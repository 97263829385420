//React
import { useState } from 'react';
import styled from 'styled-components';

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, Controller, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "./styles.css";

//Css
import {
  CenterDefault,
  Center,
  Row,
  Image,
  SizedBox,
  Blue,
  Title,
  Subscription,
  ComponentDiv,
  ComponentContainer,
} from 'pages/LandingPage/styles';

//Assets
import background from 'assets/LandingPageDev/subPage/AnalysisPage/section02/background.png';
import youtubeLogo from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube-logo.png';
import instagramLogo from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram-logo.png';
import meercatLogo from 'assets/LandingPageDev/subPage/AnalysisPage/section02/meercat-logo.png';
import { ReactComponent as ArrowLeft } from "assets/LandingPageDev/section15/arrow-left.svg"
import { ReactComponent as ArrowRight } from "assets/LandingPageDev/section15/arrow-right.svg"
import youtube01 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube01.png';
import youtube02 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube02.png';
import youtube03 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube03.png';
import youtube04 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube04.png';
import youtube05 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube05.png';
import youtube06 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube06.png';
import youtube07 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube07.png';
import youtube08 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/youtube08.png';
import instagram01 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram01.png';
import instagram02 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram02.png';
import instagram03 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram03.png';
import instagram04 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram04.png';
import instagram05 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram05.png';
import instagram06 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram06.png';
import instagram07 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram07.png';
import instagram08 from 'assets/LandingPageDev/subPage/AnalysisPage/section02/instagram08.png';

function Section01({ whetherDesktop, whetherWindow1000 }) {

  const [media, setMedia] = useState('youtube');
  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const SlideStyles = { width: whetherDesktop?'440px':'300px' };

  SwiperCore.use([Navigation])

  const params = {
    cssMode: false, // 아이폰12에서 끊어지는 현상 발생해서 추가함.
    navigation: {
      prevEl: '#js-prev',
      nextEl: '#js-next',
    },
    onSlideChange: (e) => setSwiperIndex(e.activeIndex),
  };

  return (
    <ComponentDiv whetherBackground={true}>

      <BackgroundImage src={background}/>

      <ComponentContainer whetherBackground={true}>

        <SizedBox height={whetherDesktop ? '250px' : '120px'} />

        <FlexDirection>
          <SizedBox width='450px'>
            <Title flexDirection={whetherDesktop?'column':'row'} style={{lineHeight:'130%'}}>
              <Blue>SNS 분석 리포트{!whetherDesktop&&<>&nbsp;</>}</Blue>
              미리보기!
            </Title>
            <Subscription top={whetherDesktop?'24px':'10px'}>
            </Subscription>
            <Row style={{ marginTop: whetherDesktop?'33px':'20px' }}>
              <MediaButton active={media === 'youtube'} onClick={() => setMedia('youtube')}>
                <Image src={youtubeLogo} width={whetherDesktop ? '64px' : '47px'} />
              </MediaButton>
              <MediaButton active={media === 'instagram'} onClick={() => setMedia('instagram')}>
                <Image src={instagramLogo} width={whetherDesktop ? '71px' : '52px'} />
              </MediaButton>
              <MediaButton onClick={() => {
                window.location.href = `https://meercat.io/analysis_report/sample`
              }}>
                <Image src={meercatLogo} width={whetherDesktop ? '60px' : '45px'} />
              </MediaButton>
            </Row>

            {
              whetherWindow1000
                ? <Row>
                  <NavigationButton id="js-prev" active={swiperIndex !== 0}>
                    <ArrowLeft style={{ marginRight: '3px' }} />
                  </NavigationButton>
                  <NavigationButton id="js-next" active={swiperIndex !== 7}>
                    <ArrowRight style={{ marginLeft: '3px' }} />
                  </NavigationButton>
                  <Swiper
                    scrollbar={{
                      hide: false,
                    }}
                    modules={[Scrollbar, Controller]}
                    onSwiper={setSwiper}
                    style={{ width: '316px', marginBottom: '14px' }}
                  >
                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider1</div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider2</div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider3</div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider4</div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider5</div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider6</div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider7</div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div style={{ opacity: '0', width: '316px' }}>Slider8</div>
                    </SwiperSlide>

                  </Swiper>
                </Row>
                : <SizedBox height='60px' />
            }
          </SizedBox>

          <div style={{ width: whetherWindow1000 ? `${window.innerWidth / 2 + 75}px` : `${window.innerWidth - 40}px` }}>
            <Swiper {...params}
              slidesPerView={"auto"}
              rewind={true}
              grabCursor={true}
              modules={[Controller]}
              controller={{ control: swiper }}
            >
              <SwiperSlide style={SlideStyles}>
                <Image src={media === 'youtube' ? youtube01 : instagram01} width={whetherDesktop?'410px':'273px'} height={whetherDesktop?'390px':'260px'} left={whetherDesktop?'10px':'0px'} />
              </SwiperSlide>

              <SwiperSlide style={SlideStyles}>
                <Image src={media === 'youtube' ? youtube02 : instagram02} width={whetherDesktop?'410px':'273px'} height={whetherDesktop?'390px':'260px'} left={whetherDesktop?'10px':'0px'}/>
              </SwiperSlide>

              <SwiperSlide style={SlideStyles}>
                <Image src={media === 'youtube' ? youtube03 : instagram03} width={whetherDesktop?'410px':'273px'} height={whetherDesktop?'390px':'260px'} left={whetherDesktop?'10px':'0px'}/>
              </SwiperSlide>

              <SwiperSlide style={SlideStyles}>
                <Image src={media === 'youtube' ? youtube04 : instagram04} width={whetherDesktop?'410px':'273px'} height={whetherDesktop?'390px':'260px'} left={whetherDesktop?'10px':'0px'}/>
              </SwiperSlide>

              <SwiperSlide style={SlideStyles}>
                <Image src={media === 'youtube' ? youtube05 : instagram05} width={whetherDesktop ? '410px' : '273px'} height={whetherDesktop ? '390px' : '260px'} left={whetherDesktop ? '10px' : '0px'} />
              </SwiperSlide>

              <SwiperSlide style={SlideStyles}>
                <Image src={media === 'youtube' ? youtube06 : instagram06} width={whetherDesktop ? '410px' : '273px'} height={whetherDesktop ? '390px' : '260px'} left={whetherDesktop ? '10px' : '0px'} />
              </SwiperSlide>

              <SwiperSlide style={SlideStyles}>
                <Image src={media === 'youtube' ? youtube07 : instagram07} width={whetherDesktop ? '410px' : '273px'} height={whetherDesktop ? '390px' : '260px'} left={whetherDesktop ? '10px' : '0px'} />
              </SwiperSlide>

              <SwiperSlide style={{ width: whetherDesktop ? '440px' : '300px' }}>
                <Image src={media === 'youtube' ? youtube08 : instagram08} width={whetherDesktop ? '410px' : '273px'} right={whetherDesktop ? '50px' : '10px'} left={whetherDesktop ? '10px' : '0px'} />
              </SwiperSlide>

            </Swiper>
          </div>

        </FlexDirection>

        {
            !whetherWindow1000
            && <Center>
              <Swiper
                scrollbar={{
                  hide: false,
                }}
                modules={[Scrollbar, Controller]}
                onSwiper={setSwiper}
                style={{ width: whetherDesktop?`${window.innerWidth - 80}px`:`${window.innerWidth - 40}px`, marginTop: '0px', marginRight:'40px' }}
              >
                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider1</div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider2</div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider3</div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider4</div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider5</div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider6</div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider7</div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ opacity: '0', width: '316px' }}>Slider8</div>
                </SwiperSlide>

              </Swiper>
              <Row >
                <NavigationButton id="js-prev" active={swiperIndex !== 0} style={{marginTop: whetherDesktop?'40px':'20px', marginRight:'28px'}}>
                  <ArrowLeft style={{ marginRight: '3px' }} />
                </NavigationButton>
                <NavigationButton id="js-next" active={swiperIndex !== 7} style={{marginTop: whetherDesktop?'40px':'20px', marginRight:'0px'}}>
                  <ArrowRight style={{ marginLeft: '3px' }} />
                </NavigationButton>
              </Row>
            </Center>
          }


      </ComponentContainer>

    </ComponentDiv>
  );

}

export default Section01;

const BackgroundImage = styled.img`
  width: 1160px;
  bottom: 12px;
  left: 50%;
  position: absolute;

  @media only screen and (max-width: 1000px) {
    min-width: 1160px;
    bottom: 100px;
    left: 60px;
  }

  @media only screen and (max-width: 590px) {
    min-width: 650px;
    bottom: 66px;
    left: 26px;
  }
`;

const FlexDirection = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

const MediaButton = styled.div`
  ${CenterDefault({})}
  width: 94px;
  height: 94px;
  cursor: pointer;
  margin-right: 21px;
  border-radius: 10px;

  background: ${(props) => props.active ? 'rgba(68, 128, 247, 0.1)' : '#FFFFFF'};
  border: ${(props) => props.active ? '1px solid #4480F7' : '1px solid #EEEEEE'};
  box-shadow: ${(props) => props.active ? '0px 0px 20px rgba(0, 0, 0, 0.05)' : '0px 0px 20px rgba(0, 0, 0, 0.05)'};

  @media only screen and (max-width: 590px) {
    width: 70px;
    height: 70px;
  }
`

const NavigationButton = styled.button`
  ${CenterDefault({})}
  width: 40px;
  height: 40px;
  border: 2px solid #000000;
  border-radius: 50%;
  margin-top: 81px;
  margin-right: 33px;
  background: transparent;
  opacity: ${(props) => props.active ? '0.6' : '0.2'};
  cursor: pointer;
`