//Core
import styled from 'styled-components'

//Components
import { TextDiv } from '../Tools'

//Assets
import { ReactComponent as CheckSvg } from 'assets/components/System/Toast/check.svg'
import { ReactComponent as CrossSvg } from 'assets/components/System/Toast/cross.svg'

//Types
import { ZINDEX } from 'types/common'
import { TOAST_TYPE } from 'types/toast'

function Toast({ toast }) {
  return (
    <ToastContainer>
        {toast && 
            <>
                {toast?.type === TOAST_TYPE.POSITIVE ? <CheckSvg /> : toast?.type === TOAST_TYPE.NEGATIVE ? <CrossSvg /> : <></>}
                <TextDiv size={'14px'} color={'#FFFFFF'} weight={400} style={{padding: toast.type ? '' : '0px 10px'}}>{toast?.desc}</TextDiv>
            </>
        }
    </ToastContainer>
  )
}

export default Toast

const ToastContainer = styled.div`
    width: max-content;

    padding: 10px;

    background: #333333;
    border-radius: 39px;

    position: fixed;
    left: 50%;
    bottom: -50px;
    transform: translate(-50%, 0%);

    display: flex;
    align-items: center;
    gap: 8px;

    z-index: ${ZINDEX.toast};

    animation: settings_toast 3s 1 ease;
    
    @media (max-width: 590px){
        animation: settings_toast_mobile 3s 1 ease;
    }

    @keyframes settings_toast {
        0%{
            transform: translate(-50%, 0%);
        }
        8%{
            transform: translate(-50%, -100px);
        }
        90%{
            transform: translate(-50%, -100px);
            opacity: 1;
        }
        100%{
            transform: translate(-50%, 0%);
            opacity: 0;
        }
    }

    @keyframes settings_toast_mobile {
        0%{
            transform: translate(-50%, 0%);
        }
        8%{
            transform: translate(-50%, -150px);
        }
        90%{
            transform: translate(-50%, -150px);
            opacity: 1;
        }
        100%{
            transform: translate(-50%, 0%);
            opacity: 0;
        }
    }
`