
//React
import styled from "styled-components"

//Types
import { BUTTONTYPE } from "types/common"

function Button({ button, ...props}) {
  return (
    <ButtonWrapper type={button?.type ?? 'next'} isActive={button?.isActive} onClick={button?.onClick} {...props}>
        {button?.desc}
    </ButtonWrapper>
  )
}

export default Button

const ButtonWrapper = styled.button`
    width: 100%;
    height: 100%;

    background: ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].normal.background};
    border: 1px solid ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].normal.border};
    color: ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].normal.color};
    border-radius: 5px;

    cursor: pointer;
    user-select: none;
    
    &:active{
        background: ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].effect.background};
        border: 1px solid ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].effect.border};
        color: ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].effect.color};
    }

    @media (min-width: 591px){

        &:hover{            
            background: ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].effect.background};
            border: 1px solid ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].effect.border};
            color: ${props => BUTTONTYPE[props.type][props.isActive ? 'active' : 'disabled'].effect.color};
        }

        transition: all 0.15s linear;
    }
`