import styled from 'styled-components'

//assets
import creatorlyLogo from 'assets/common/creatorly_logo_v2.svg'

function CreatorlyLogo({ noCursor, ...props }) {

  return <LogoImg noCursor={noCursor} src={creatorlyLogo} {...props} />
}

export default CreatorlyLogo

const LogoImg = styled.img`
    width: 96px;
  
    cursor: ${props => props.noCursor ? '' : 'pointer'};

    @media (max-width: 590px){
      width: 76.5px;
    }
`;