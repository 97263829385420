import React from 'react'
import styled, { css } from 'styled-components'

function LoadingDiv({blue}) {
  return (
    <Wrapper>
        <Loader blue={blue} />
    </Wrapper>
  )
}

export default LoadingDiv

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`

const Loader = styled.section`
    font-size: 3px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);    

    ${({blue}) => blue &&
    css`
      -webkit-animation: load6 1.1s infinite ease;
      animation: load6 1.1s infinite ease;
    `
    }
`