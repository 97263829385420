//React
import { useState } from "react";
import styled from 'styled-components';

//Css
import {
  CenterDefault,
  Image,
  Text,
  FlexBox,
  SizedBox,
  Blue,
  Title,
  ComponentDiv,
  ComponentContainer,
} from 'pages/LandingPage/styles';

//Assets
import icon from 'assets/LandingPageDev/subPage/AnalysisPage/section05/Q-icon.png';

const sampleAccordionData = [
  {
    id: '1',
    title: '계정 비밀번호를 변경한 경우',
    content: '인스타그램 비밀번호를 변경하게 된다면 인스타그램과 관련된 모든 서비스가 자동적으로 로그아웃이 되어 분석 리포트 연동이 끊기게 됩니다. 번거롭겠지만 비밀번호를 변경하신 경우, 다시 연동을 진행해 주세요.'
  },
  {
    id: '2',
    title: '새로운 기기로 로그인한 경우',
    content: '자주 사용하던 기기가 아닌 새로운 기기에서 인스타그램에 여러번 접속하는 경우, 인스타그램에서 해당 계정이 해킹됐거나 혹은 매크로 사용자로 인식하여 연동을 끊어버리게 됩니다. 보안되지 않은 네트워크의 사용이나 새로운 기기에서의 접속은 피하는 게 좋아요.'
  },
  {
    id: '3',
    title: '프로페셔널 계정을 해제한 경우',
    content: '인스타그램은 프로페셔널 계정에 한해서만 데이터를 분석할 수 있습니다. 프로페셔널 계정을 일반 계정으로 전환하셨다면, 처음 연동하셨을 당시의 절차와 동일하게 재연동을 진행해 주세요.'
  },
  {
    id: '4',
    title: '페이스북이 비활성화된 경우',
    content: '인스타그램과 페이스북 페이지는 서로 연동이 되어있기 때문에 페이스북 페이지의 정보 변경, 삭제 또는 장기간 미접속으로 인해 페이스북이 비활성화되면 인스타그램 연동이 끊기게 됩니다. 페이스북을 잘 사용하지 않더라도 한 달에 한 번씩 접속하여 비활성화를 방지하는 것이 좋아요.'
  },
];

function Section01({ whetherDesktop }) {

  const [whetherFirstAccordionOpened, setWhetherFirstAccordionOpened] = useState(false);
  const [whetherSecondAccordionOpened, setWhetherSecendAccordionOpened] = useState(false);
  const [whetherThirdAccordionOpened, setWhetherThirdAccordionOpened] = useState(false);
  const [whetherFourthAccordionOpened, setWhetherFourthAccordionOpened] = useState(false);

  return (
    <ComponentDiv style={{ background: '#FCFCFC' }}>

      <ComponentContainer center={true}>

        <SizedBox height={whetherDesktop ? '100px' : '50px'} />

        
        <Title flexDirection={window.innerWidth>750?'row':'column'} center={true}>
          인스타그램 분석 리포트<Blue>{window.innerWidth>750&&<>&nbsp;</>}연동이 끊겼을 경우</Blue>
        </Title>

        <SizedBox height='40px' />

        <TitleDiv active={whetherFirstAccordionOpened} onClick={() => setWhetherFirstAccordionOpened(!whetherFirstAccordionOpened)}>
          <Image src={icon} width={whetherDesktop ? '36px' : '24px'} right='20px' />
          {sampleAccordionData[0].title}
          <FlexBox/>
          <Arrow active={whetherFirstAccordionOpened}/>
        </TitleDiv>
        <ContentDiv active={whetherFirstAccordionOpened}>
          <ContentText>
            {sampleAccordionData[0].content}
          </ContentText>
        </ContentDiv>

        <TitleDiv active={whetherSecondAccordionOpened} onClick={() => setWhetherSecendAccordionOpened(!whetherSecondAccordionOpened)}>
          <Image src={icon} width={whetherDesktop ? '36px' : '24px'} right='20px' />
          {sampleAccordionData[1].title}
          <FlexBox/>
          <Arrow active={whetherSecondAccordionOpened}/>
        </TitleDiv>
        <ContentDiv active={whetherSecondAccordionOpened}>
          <ContentText>
            {sampleAccordionData[1].content}
          </ContentText>
        </ContentDiv>

        <TitleDiv active={whetherThirdAccordionOpened} onClick={() => setWhetherThirdAccordionOpened(!whetherThirdAccordionOpened)}>
          <Image src={icon} width={whetherDesktop ? '36px' : '24px'} right='20px' />
          {sampleAccordionData[2].title}
          <FlexBox/>
          <Arrow active={whetherThirdAccordionOpened}/>
        </TitleDiv>
        <ContentDiv active={whetherThirdAccordionOpened}>
          <ContentText>
            {sampleAccordionData[2].content}
          </ContentText>
        </ContentDiv>

        <TitleDiv active={whetherFourthAccordionOpened} onClick={() => setWhetherFourthAccordionOpened(!whetherFourthAccordionOpened)}>
          <Image src={icon} width={whetherDesktop ? '36px' : '24px'} right='20px' />
          {sampleAccordionData[3].title}
          <FlexBox/>
          <Arrow active={whetherFourthAccordionOpened}/>
        </TitleDiv>
        <ContentDiv active={whetherFourthAccordionOpened}>
          <ContentText>
            {sampleAccordionData[3].content}
          </ContentText>
        </ContentDiv>

        <SizedBox height={whetherDesktop ? '100px' : '50px'} />

      </ComponentContainer>

    </ComponentDiv>
  );

}

export default Section01;

const TitleDiv = styled.div`
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  padding: 0 30px 0 30px;

  cursor: pointer;

  ${Text({
    fontSize: '22px',
    lineHeight: '23px',
  })};
  color: ${(props) => props.active ? '#4480F7' : '#333333'};
  font-weight: ${(props) => props.active ? '800' : '500'};

  border-bottom: ${(props) => props.active ? 'none' : ''};
  border-bottom-left-radius: ${(props) => props.active ? '0' : ''};
  border-bottom-right-radius: ${(props) => props.active ? '0' : ''};

  ${CenterDefault({
    flexDirection: 'row',
    justifyContent: 'flex-start'
  })}

  @media only screen and (max-width: 590px) {
    height: 52px;
    padding: 0 22px 0 22px;
    font-size: 14px;
  }
`;

const ContentDiv = styled.div`
  width:100%;
  max-height: ${(props) => props.active ? '500px' : '0'};
  background: #FFFFFF;
  margin-bottom: 20px;

  border: ${(props) => props.active ? '1px solid #EEEEEE' : 'none'}; ;
  border-radius: 10px;
  ${CenterDefault({})}

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  transition: max-height 0.15s ease-out;
  overflow: hidden;

  @media only screen and (max-width: 590px) {
    margin-bottom: 10px;
  }
`;

const ContentText = styled.div`
  width:100%;
  padding: 30px 100px;

  ${Text({
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '180%',
    color: 'opacity60'
  })};

  @media only screen and (max-width: 590px) {
    padding: 10px 22px 16px;
    font-size: 12px;
  }
`;

const Arrow = styled.div`
  width: 14px;
  height: 14px;
  border-top: 2px solid #CCCCCC;
  border-right: 2px solid #CCCCCC;
  transform: ${(props) => props.active ? 'rotate(315deg)' : 'rotate(495deg)'};
  margin-top: ${(props) => props.active ? '0px' : '-10px'};
  transition: all 0.15s;

  @media only screen and (max-width: 590px) {
    width: 10px;
    height: 10px;
  }
`;