
// 도메인 단위 구조
const TOAST_TYPE = {
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE'
}

const CREATOR_TOAST = {        
        DELEGATION_FAILED: {
            SCOPE: {
                type: TOAST_TYPE.NEGATIVE,
                desc: '모든 권한을 허용해 주세요'      
            },
            DUPLICATE: {
                type: TOAST_TYPE.NEGATIVE,
                desc: '이미 가입한 유튜브 채널입니다'
            },
            UNKNOWN: {
                type: TOAST_TYPE.NEGATIVE,
                desc: '다시 시도해 주세요'
            },
        },
        OFFER_PRICE_THOUSAND: {
            type: '',
            desc: '제안 금액은 천원 단위로 입력해 주세요'            
        },
        DOWNLOAD_FAILED: {
            type: TOAST_TYPE.NEGATIVE,
            desc: '다운로드 실패'            
        },
        DOWNLOAD_IN_PROGRESS: {
            type: '',
            desc: '로딩중입니다'            
        },
        BOOKMARK_ADDED: {    // 북마크 추가
            type: TOAST_TYPE.POSITIVE,
            desc: '북마크에 추가되었습니다'
        },
        BOOKMARK_REMOVED: {    // 북마크 제거
            type: TOAST_TYPE.POSITIVE,
            desc: '북마크에서 제거되었습니다'
        },
        LINK_COPIED: {    // 광고제안 링크 복사
            type: TOAST_TYPE.POSITIVE,
            desc: '링크가 복사되었습니다'
        },
        PROPOSAL_ACCEPTED: {      // 신규 제안 수락
            type: TOAST_TYPE.POSITIVE,
            desc: '캠페인 수락이 완료되었어요'
        },
        PROPOSAL_REJECTED: {      // 신규 제안 거절
            type: TOAST_TYPE.POSITIVE,
            desc: '캠페인 거절이 완료되었어요'
        },
        NEGOTIATED_PROPOSAL_ACCEPTED: {    // 협상안 수락
            type: TOAST_TYPE.POSITIVE,
            desc: '협상안 수락이 완료되었어요'
        },
        NEGOTIATED_PROPOSAL_REJECTED: {    // 협상안 거절
            type: TOAST_TYPE.POSITIVE,
            desc: '협상안 거절이 완료되었어요'
        },
        COMPROMISE_SENT: {
            type: TOAST_TYPE.POSITIVE,
            desc: '협의 내용이 전달되었습니다'            
        },
        PLAN_SENT: {      // 기획안 단계 기획안 전달
            type: TOAST_TYPE.POSITIVE,
            desc: '기획안이 전달되었습니다'
        },
        PLAN_MESSAGE_SENT: {      // 기획안 단계 메세지 전달
            type: TOAST_TYPE.POSITIVE,
            desc: '메세지가 전달되었습니다'
        },
        PLAN_APPROVED: {      // 기획안 확정
            type: TOAST_TYPE.POSITIVE,
            desc: '기획안이 확정되었습니다'
        },
        PROTOTYPE_SENT: {    // 가편 전달
            type: TOAST_TYPE.POSITIVE,
            desc: '가편이 광고주에게 전달되었습니다'
        },
        PROTOTYPE_COMMENT_ADDED: {      // 가편 수정사항에 댓글 추가
            type: TOAST_TYPE.POSITIVE,
            desc: '댓글이 전달되었습니다'
        },
        PROTOTYPE_EDIT_REQEUST_APPROVED: {      // 가편 수정사항 수락
            type: TOAST_TYPE.POSITIVE,
            desc: '수정사항이 수락되었습니다'
        },
        UPLOAD_SENT: {      // 컨텐츠 업로드 전달
            type: TOAST_TYPE.POSITIVE,
            desc: '업로드 컨텐츠를 전달했습니다'
        },
        YOUTUBE_CHANNEL_REGISTERED: {    // 유튜브 인증 완료
            type: TOAST_TYPE.POSITIVE,
            desc: '유튜브 인증이 완료되었습니다'
        },
        BANK_INFO_CHANGED: {      // 계좌점유 인증 완료
            type: TOAST_TYPE.POSITIVE,
            desc: '계좌번호 인증이 완료되었습니다'
        },
        BUSINESS_INFO_CHANGED: {      // 사업자 정보 변경 완료
            type: TOAST_TYPE.POSITIVE,
            desc: '사업자 정보가 변경되었습니다'
        },
        PRICE_INFO_CHANGED: {     // 캠페인 단가 변경 완료
            type: TOAST_TYPE.POSITIVE,
            desc: '캠페인 단가가 변경되었습니다'
        },
        REPORT_ALARM_CHANGED: {   // 분석 리포트 알림 설정 변경 완료
            type: '',
            desc: ''
        },
        FAVORITE_CAMPAIGNS_CHANGED: {     // 선호하는 캠페인 변경 완료
            type: TOAST_TYPE.POSITIVE,
            desc: '선호하는 캠페인이 변경되었습니다'
        },
        PAGE_COLOR_CHANGED: {     // 광고제안 페이지 스타일 변경 완료
            type: TOAST_TYPE.POSITIVE,
            desc: '페이지 스타일이 변경되었습니다'
        },
        PASSWORD_CHANGED: {   // 비밀번호 변경 완료
            type: TOAST_TYPE.POSITIVE,
            desc: '비밀번호가 변경되었습니다'
        },
        DELETE_FAILED: {    // 회원탈퇴 실패
            type: TOAST_TYPE.NEGATIVE,
            desc: '회원탈퇴에 실패했습니다'
        },
        BANK_AUTH_FAILED: {
            TRIAL_EXCEED: {
                type: TOAST_TYPE.NEGATIVE,
                desc: '하루 최대 인증 가능 횟수를 초과했습니다'
            },
            WRONG_INFO: {
                type: TOAST_TYPE.NEGATIVE,
                desc: '입력한 정보를 다시 확인해 주세요'
            },
            UNDER_INSPECTION: {
                type: TOAST_TYPE.NEGATIVE,
                desc: '은행 서비스 점검 시간입니다'
            },
        },
        SAMPLE_ALERT: {
            type: TOAST_TYPE.NEGATIVE,
            desc: '샘플 캠페인은 버튼이 클릭되지 않습니다'
        },
        SAMPLE_DELETED: {
            type: TOAST_TYPE.POSITIVE,
            desc: '샘플 캠페인이 삭제되었습니다'
        }
}

const SPONSOR_TOAST = {
    PLAN_SENT: {      // 기획안 단계 기획안 전달
        type: '',
        desc: ''
    },
    PLAN_MESSAGE_SENT: {      // 기획안 단계 메세지 전달
        type: '',
        desc: ''
    },
    PLAN_APPROVED: {      // 기획안 확정
        type: TOAST_TYPE.POSITIVE,
        desc: '기획안 승인을 요청했습니다'
    },
    EDIT_REQUEST_SAVED: {    // 가편 영상 수정사항 저장
        type: TOAST_TYPE.POSITIVE,
        desc: '수정사항이 저장되었습니다'
    },
    ADDITIONAL_REQUEST_SAVED: {    // 가편 추가 수정사항 저장
        type: TOAST_TYPE.POSITIVE,
        desc: '수정사항이 저장되었습니다'
    },
    EDIT_REQUEST_DELETED: {    // 가편 영상 수정사항 저장
        type: TOAST_TYPE.POSITIVE,
        desc: '수정사항이 삭제되었습니다'
    },
    ADDITIONAL_REQUEST_DELETED: {    // 가편 추가 수정사항 저장
        type: TOAST_TYPE.POSITIVE,
        desc: '수정사항이 삭제되었습니다'
    },
    EDIT_REQUSET_SENT: {    // 가편 수정사항 전달
        type: '',
        desc: ''
    },
    COMMENT_ADDED: {
        type: TOAST_TYPE.POSITIVE,
        desc: '댓글이 전달되었습니다'
    },
    SCHEDULE_SET: {
        type: TOAST_TYPE.POSITIVE,
        desc: '희망 일자가 설정되었습니다'
    },
    APPROVED: {      // 가편 수락
        type: '',
        desc: ''
    },
    UPLOAD_APPROVED: {      // 컨텐츠 업로드 승인
        type: TOAST_TYPE.POSITIVE,
        desc: '업로드 컨텐츠를 승인했습니다'
    },
}

const COMMON_TOAST = {
    SESSION_EXPIRED: {      // credential 세션 만료
        type: '',
        desc: '로그인 세션이 만료되었습니다'
    },
    INSUFFICIENT_LOGIN_INPUT: {      // credential 세션 만료
        type: TOAST_TYPE.NEGATIVE,
        desc: '로그인 정보를 입력해주세요'
    },
    CODE_SENT: {      // 인증번호 발송
        type: TOAST_TYPE.POSITIVE,
        desc: '인증번호가 발송되었습니다'
    },
    EMAIL_AUTHED: {      // 이메일 인증 성공
        type: TOAST_TYPE.POSITIVE,
        desc: '이메일이 인증되었습니다'
    },
    EMAIL_AUTHED_FAILED: {      // 이메일 인증 성공
        type: TOAST_TYPE.NEGATIVE,
        desc: '이메일이 인증에 실패했습니다'
    },
    OFFER_PRICE_THOUSAND: {
        type: '',
        desc: '제안 금액은 천원 단위로 입력해주세요'            
    },
    RETRY: {
        type: TOAST_TYPE.NEGATIVE,
        desc: '다시 시도해 주세요'       
    }
}

Object.freeze([TOAST_TYPE, CREATOR_TOAST. SPONSOR_TOAST, COMMON_TOAST])

export { TOAST_TYPE, CREATOR_TOAST, SPONSOR_TOAST, COMMON_TOAST }