import React, { useRef } from 'react'

//Components
import MainPopup from 'components/System/Popup/MainPopup'
import SmallPopup from 'components/System/Popup/SmallPopup'
import LoadingDiv from 'components/LoadingDiv'

function useStandardPopup() {
    /* model */
    const popupRef = useRef()
    const [popup, setPopup] = React.useState(null)
    const [isPopupLoading, setIsPopupLoading] = React.useState(null)

    const handleStandardPopup = React.useMemo(function(){   // controller

        return {
          set: function(newPopup){
            setIsPopupLoading(false)
            setPopup(newPopup)
          },
          remove: function(){
            
            setPopup(null)
          },
          startLoading: function(){
            setIsPopupLoading(true)
          },
          endLoading: function(){
            setIsPopupLoading(false)
          }
        }
    }, [popup, setPopup, isPopupLoading, setIsPopupLoading, popupRef])

    const component = React.useMemo(function () {  // view

        return (
            <>
                {popup && 
                <MainPopup
                    popupRef={popupRef}
                    close={{
                        isActive: popup?.close?.isActive,
                        onClick: popup?.close?.onClick
                    }}
                    backArrow={{
                        isActive: popup?.backArrow?.isActive,
                        onClick: popup?.backArrow?.onClick
                    }}
                    content={popup.content}
                    button={{
                        type: popup?.button?.type ?? 'next',
                        isActive: popup?.button?.isActive,
                        desc: isPopupLoading ? <LoadingDiv /> : popup?.button?.desc,
                        onClick: popup?.button?.onClick
                    }}
                />}
            </>
        )
    }, [popup, isPopupLoading])

    return { handleStandardPopup, component }
}

export default useStandardPopup