import { Link as link } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Wrapper = styled.footer<{ isActive?: boolean, isDisplay?: boolean }>`
  width: 100%;
  min-width: 1310px;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #3d3d3d;
  font-style: normal;
  padding: 16px 4px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 12.1653px;
  line-height: 12px;
  color: #c5c5c5 !important;
  text-align: center;

  @media (max-width: 1200px){

    display: none;
  }

`;

const Link = styled(link)`
  color: #c5c5c5;
  text-decoration: underline;
`;

const Footer = () => {

  return (
    <Wrapper isActive={!isMobile}>
      <div style={{ marginBottom: '6px' }}>
        <a href="" onClick={function(e){e.preventDefault(); window.open('/policy')}} style={{color: '#c5c5c5'}}>이용약관</a> |&nbsp;
        <a href="" onClick={function(e){e.preventDefault(); window.open('/privacy')}} style={{color: '#c5c5c5'}}>개인정보처리방침</a>
      </div>
      주식회사어웨이크코퍼레이션 | 서울특별시 강남구 언주로 537, 11층 (역삼동,
      에이비티타워) | 대표 : 김민준 | 사업자등록번호 : 841-88-01897 | 통신판매업
      : 2020-서울강남-02980 | 대표 번호 : 02-6953-0212 | 이메일:
      contact@awakepeople.com
    </Wrapper>
  );
};

export default Footer;
