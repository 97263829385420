const LOGIN_COOKIE_NAMES = {
    ID: 'app-integrateId',
    AVATAR: 'app-avatarId',
    TOKEN: 'dlrjsxhzmsdlqslek'
}

const JOIN_COOKIE_NAMES = {
    ID: 'join_id',
    TOKEN: 'join_token',
    AVATAR: 'join_avatarId',
    EMAIL: 'join_email',
    PHONE: 'join_phone',
    INITIAL_AVATAR: 'initial_join_link'
}

const PAGEVIEW_COOKIE_NAMES = {
    COUNTED: 'whether_counted',
}

Object.freeze([LOGIN_COOKIE_NAMES, JOIN_COOKIE_NAMES, PAGEVIEW_COOKIE_NAMES])

export { LOGIN_COOKIE_NAMES, JOIN_COOKIE_NAMES, PAGEVIEW_COOKIE_NAMES }