//React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//3rd
import { isMobile } from "react-device-detect";
import styled from "styled-components";

//components
import CreatorlyLogo from "components/System/Logo";

//Assets
import creatorlyLogo from "assets/common/header-creatorly-logo.png";
import helpIcon from "assets/common/helpIcon.svg"
import { ZINDEX } from "types/common";

const Wrapper = styled.header`
  width: 100%;
  height: 8%;
  max-height: 80px;

  position: fixed;
  left: 0;
  top: 0;
  background-color: #ffffff;
  display: ${props => (props.isVisible ? 'flex' : isMobile ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  padding: ${props => (props.isMobile ? '9px 5%' : '9px 3%')};
  z-index: ${ZINDEX.layout};

  @media (max-width: 1200px){

    display: none;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  width: 140px;

  cursor: pointer;
`;

// const GradientText = styled.span<{isMobile?: boolean}>`
//   font-size: ${props => (props.isMobile ? '17px' : '90%')};
//   background: linear-gradient(90.84deg, #4480F7 3.32%, #B442FA 65.35%);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// `

const HelpIcon = styled.img`
  width: ${props => (props.isMobile ? '100px' : '120px')};

  cursor: pointer;
`

const Header = ({...props}) => {
  const navigate = useNavigate()
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(function () {
    window.addEventListener('resize', function () {

      setInnerHeight(window.innerHeight);
    })
  })

  return (
    // <Wrapper isMobile={innerHeight > 850 && !isMobile}>
    <Wrapper
      isMobile={isMobile}
      isVisible={innerHeight > 800 && !isMobile}
      {...props}
    >
      <ContentBox>
        <CreatorlyLogo 
          onClick={function () {

            navigate('/');
          }}
        />
        <HelpIcon
          // id="btn-launch-chat2"
          isMobile={isMobile}
          src={helpIcon}
          onClick={function(){ window.ChannelIO('showMessenger') }}
        />
      </ContentBox>
    </Wrapper>
  );
};

export default Header;
