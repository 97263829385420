//React
import React, { Fragment } from 'react'
import styled from 'styled-components'

//Components
import Button from 'components/System/Button'
import { TextDiv } from 'components/System/Tools'

//Assets
import { ReactComponent as Close } from "assets/common/close20-black-icon.svg"

//Types
import { ZINDEX } from 'types/common'

function SmallPopup({ title, buttons, close, popupRef}) {
  return (
    <Fragment>
      <PopupContainer ref={popupRef}>
        {close?.isActive && <CloseSvg onClick={close?.onClick} />}
        <ContentWrapper>
          <TitleContainer>
            <TitleWrapper>
              <TextDiv size={'20px'} sizeMobile={'18px'} color={'#111111'} weight={700} style={{ marginBottom: 5 }}>{title?.main}</TextDiv>
              <TextDiv size={'16px'} color={'#777777'} weight={400} lineHeight={'160%'} style={{ textAlign: 'center' }}>{title?.sub}</TextDiv>
            </TitleWrapper>
          </TitleContainer>
          <ButtonWrapper>
            {buttons?.map(function(button, index){

              return(
                <Button 
                  key={`button${index}`}
                  button={button}
                />
              )
            })}
          </ButtonWrapper>
        </ContentWrapper>
      </PopupContainer>
      <PopupBackground />
    </Fragment>
  )
}

export default SmallPopup

const TitleContainer = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);

  overflow: hidden;
`

const PopupContainer = styled.div`
  width: 95%;
  max-width: 400px;
  height: 289px;
  max-height: 289px;

  padding: 80px 30px 30px 30px;

  background: white;
  border-radius: 10px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: ${ZINDEX.popup.small.content};

  animation: contentpopup_show 0.1s 1;

  @keyframes contentpopup_show {
      0%{
          opacity: 0;
          transform: translate(-50%, -50%) scale(0.98);
      }
      98%{
          opacity: 1;
          transform: translate(-50%, -50%) scale(1.002);
      }
      100%{
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
      }
  }

  @keyframes contentpopup_hide {
      0%{
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
          top: 50%;
      }
      100%{
          opacity: 0;
          transform: translate(-50%, -50%) scale(0.98);
          top: 48%;
      }
  }

  @media (max-width: 590px){
    max-width: 320px;
    max-height: 245px;

    padding: 60px 20px 20px 20px;
  }
`

const PopupBackground = styled.div`
  min-width: 100%;
  min-height: 100%;

  background: rgb(0, 0, 0, 0.6);
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: ${ZINDEX.popup.small.background};
`

const ButtonWrapper = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
`

const CloseSvg = styled(Close)`
  position: absolute;
  top: 30px;
  right: 30px;

  cursor: pointer;

  @media (max-width: 590px){
    top: 20px;
    right: 20px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;

  overflow: hidden;
`

const TitleWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    overflow: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar{
        display: none;
    }
`