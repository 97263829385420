import React from 'react'
import styled, { css } from 'styled-components'
import { ButtonColorSet } from 'types/common'

function Button({isActive, type, description, textColor, onClick, style}) {

  return (
    <Wrapper textColor={textColor} isActive={isActive} type={type} onClick={onClick} style={style}>
        {description}
    </Wrapper>
  )
}

export default Button

const Wrapper = styled.div`
    flex: 1;
    width: 100%;
    max-width: 320px;
    min-height: 60px;
    max-height: 60px;

    border-radius: 5px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    //default
    background: ${ButtonColorSet['disabled'].main};
    color: ${props => props.textColor ? props.textColor : 'white'};

    //active
    ${({isActive}) =>
        isActive &&
        css`
          background: ${props => props.type ? ButtonColorSet[props.type].main : ButtonColorSet['disabled'].main};
        `
    }

    @media (min-width: 590px){

      &:hover{
        ${({isActive}) =>
        isActive &&
        css`
          background: ${props => props.type ? ButtonColorSet[props.type].mainActive : ButtonColorSet['disabled'].mainActive};
        `
        }
      }
    }

    /* &:active{
      transform: scale(0.98);
    } */
  
    transition: all 0.05s linear;

    user-select: none;
    cursor: pointer;
`