export const INPUTDEFAULT = {
    autoComplete: 'off',
    autoCapitalize: 'off',
    spellCheck: false
}

export const SCROLL_DEFAULT = `
    ::-webkit-scrollbar{
        width: 7px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f4f4f4;
    }
`

export const LOGIN_COOKIE_DEFAULT = {
    path: '/', 
    sameSite: 'none', 
    secure: true, 
    domain: '.creator.ly', 
}