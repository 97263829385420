//React
import React, { Fragment } from 'react'
import styled from 'styled-components'

//Assest
import { ReactComponent as Close } from "assets/common/close20-black-icon.svg"
import { ReactComponent as BackArrow } from 'assets/common/back-arrow-icon.svg'

//Components
import Button from 'components/System/Button'
import { ZINDEX } from 'types/common'

function MainPopup({backArrow, close, content, button, popupRef}) {

  return (
    <Fragment>
        <PopupContainer ref={popupRef}>
            <ManipulateWrapper>
                  <div>
                      {backArrow?.isActive && <BackArrowSvg onClick={backArrow?.onClick} />}
                  </div>
                  <div>
                      {close?.isActive && <CloseSvg onClick={close?.onClick} />}
                  </div>
            </ManipulateWrapper>
            <ContentWrapper isButton={button}>
                {content}
            </ContentWrapper>
            {button &&
                <ButtonWrapper>
                    <Button button={button} />
                </ButtonWrapper>
            }
        </PopupContainer>
        <PopupBackground />
    </Fragment>
  )
}

export default MainPopup

const ContentWrapper = styled.div`
    width: 100%;
    height: ${props => props.isButton ? 'calc(100% - 80px - 60px)' : 'calc(100% - 80px)'};
    max-height: ${props => props.isButton ? 'calc(100% - 80px - 60px)' : 'calc(100% - 80px)'};
    
    margin-bottom: ${props => props.isButton ? '20px' : '0px'};

    overflow: hidden;
`

const ButtonWrapper = styled.div`
    width: 100%;
    min-height: 60px;
`

const ManipulateWrapper = styled.div`
    width: 100%;
    height: 80px;

    padding: 30px 0px 30px 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 590px){
        width: 100vw;

        padding: 0px 20px;

        border-bottom: 1px solid #F6F6F6;
        margin-bottom: 30px;
    }
`

const BackArrowSvg = styled(BackArrow)`
    cursor: pointer;
`

const CloseSvg = styled(Close)`
    cursor: pointer;
`

const PopupContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 570px;

    padding: 0px 30px 30px 30px ;

    background: white;
    border-radius: 5px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: ${ZINDEX.popup.content};

    @media (min-width: 591px){

        animation: contentpopup_show 0.1s 1;

        @keyframes contentpopup_show {
            0%{
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.98);
            }
            98%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1.002);
            }
            100%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
        }

        @keyframes contentpopup_hide {
            0%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
            98%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1.002);
            }
            100%{
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.98);
            }
        }

    }


    @media (max-width: 590px){
        min-width: 100vw;
        min-height: 100%;
        max-height: 100%;
        
        padding: 0px 20px 30px 20px;

        background: white;
        border-radius: 0px;
        
        position: fixed;
        top: 0px;
        left: 0px;
        transform: translate(0%, 0%);

        animation: show_up_mobile 0.4s 1 ease;
    }
`

const PopupBackground = styled.div`
    width: 100%;
    height: 100%;

    background: rgb(0, 0, 0, 0.6);

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    user-select: all;

    z-index: ${ZINDEX.popup.background};

    @media (max-width: 590px){
        top: 50%;
        left: 50%;
        padding: 40px 20px 90px 20px;        
    }
`