import { useContext, useLayoutEffect } from 'react'

//utils
import loginRedirect from 'modules/Login/utils/loginRedirect'

//Context
import CredentialContext from 'modules/Credential/context'

function PublicRoute({ children }:any ) {
    const { validity } = useContext(CredentialContext)

    useLayoutEffect(function(){

        if(validity?.isValid){

            loginRedirect()
        }
    }, [validity])

    return (
        <>
            {(!validity?.isValid && validity?.isChecked) && children}
        </>
    )
}

export default PublicRoute