import React, { useRef } from 'react'

//Components
import SmallPopup from 'components/System/Popup/SmallPopup'
import LoadingDiv from 'components/LoadingDiv'

function useSmallPopup() {
    /* model */
    const popupRef = useRef()
    const [popup, setPopup] = React.useState(null)
    const [isPopupLoading, setIsPopupLoading] = React.useState(null)

    const handlePopup = React.useMemo(function(){   // controller

        return {
          set: function(newPopup){
            setIsPopupLoading(false)
            setPopup(newPopup)
          },
          remove: function(){
            
            popupRef.current.addEventListener('animationend', function(){

              setPopup(null)
            })
            popupRef.current.style.animation = 'contentpopup_hide 0.1s 1'
          },
          startLoading: function(){
            setIsPopupLoading(true)
          },
          endLoading: function(){
            setIsPopupLoading(false)
          }
        }
    }, [popup, setPopup, isPopupLoading, setIsPopupLoading, popupRef])

    const component = React.useMemo(function () {  // view

        return (
            <>
                {popup && 
                <SmallPopup
                    popupRef={popupRef}
                    close={{
                        isActive: popup?.close?.isActive,
                        onClick: popup?.close?.onClick
                    }}
                    title={popup.title}
                    buttons={[{
                        type: popup?.button?.type ?? 'next',
                        isActive: popup?.button?.isActive,
                        desc: isPopupLoading ? <LoadingDiv /> : popup?.button?.desc,
                        onClick: popup?.button?.onClick
                    }]}
                />}
            </>
        )
    }, [popup, isPopupLoading])

    return { handlePopup, component }
}

export default useSmallPopup