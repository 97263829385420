import styled from "styled-components"

export const Text = styled.div<{
    size?: string | number;
    mobileSize?: string | number;
    weight?: string | number;
    lineHeight?: string | number;
    opacity?: string | number;
}>`
font-size: ${props => props.size ?? '18px'};
font-weight: ${props => props.weight ?? '18px'};
color: ${props => props.color ?? 'black'};
line-height: ${props => props.lineHeight ?? '130%'};
opacity: ${props => props.opacity ?? '1'};

user-select: none;

@media (max-width: 590px){
    font-size: ${props => props.mobileSize ? props.mobileSize : props.size ?? '18px'};
}
`