//React
import styled from 'styled-components';

//Css
import { Text } from 'components/Text';
import { Color } from 'components/Color';

import { Image } from 'pages/LandingPage/styles';

//Assets
import honeytip from 'assets/LandingPageDev/subPage/AnalysisPage/section04/honeytip.png';
import service from 'assets/LandingPageDev/subPage/AnalysisPage/section04/service.png';
import feedback from 'assets/LandingPageDev/subPage/AnalysisPage/section04/feedback.png';
import banner from 'assets/LandingPageDev/subPage/AnalysisPage/section04/banner.png';

function Section04({ whetherDesktop }) {

  return (
    <ComponentWrapper flow={'column nowrap'} align={'center'} mobileAlign={'center'} gap={'60px'}>
      <Text40 size={'40px'} weight={800} style={{ textAlign: 'center', lineHeight: '160%' }} >
        SNS 분석 리포트
        <br />
        <Color>더 스마트하게 쓰는 방법!</Color>
      </Text40>
      <StepContainer>
        <StepWrapper>
          <StepImageWrapper background={'linear-gradient(286.05deg, rgba(252, 233, 137, 0.2) 6.96%, rgba(255, 220, 132, 0.2) 38.04%, rgba(255, 220, 132, 0.2) 63.27%, rgba(255, 148, 50, 0.2) 92.55%)'}>
            <HoneyTipImg src={honeytip} />
          </StepImageWrapper>
          <StepTextWrapper>
            <Text mobileSize={'16px'} opacity={0.4} lineHeight='180%'>Service 01</Text>
            <Text size={'30px'} mobileSize={'20px'} opacity={1} weight={800} lineHeight='140%'>리포트 확인하고</Text>
            <Text size={'30px'} mobileSize={'20px'} opacity={1} weight={800} lineHeight='140%'><Color color={'#FFC530'}>미어캣 꿀TIP&nbsp;</Color>얻어 가기!</Text>
            <Desc opacity={0.6} mobileSize={'16px'} style={{ width: '75%' }} >분석 리포트 하단에 있는 미어캣이 크리에이터의 발전을 위해 꿀 TIP 들을 알려주고 있으니 꼭 확인하기!</Desc>
          </StepTextWrapper>
        </StepWrapper>

        <StepWrapper flow={'column-reverse nowrap'} justify={'flex-end'}>
          <StepTextWrapper style={{ paddingLeft: whetherDesktop ? '40px' : '20px' }}>
            <Text mobileSize={'16px'} opacity={0.4} lineHeight='180%'>Service 02</Text>
            <Text size={'30px'} mobileSize={'20px'} opacity={1} weight={800} lineHeight='140%'>SNS를 키울 수 있는</Text>
            <Text size={'30px'} mobileSize={'20px'} opacity={1} weight={800} lineHeight='140%'><Color color={'#FF5858'}>서비스 활용하기!</Color></Text>
            <Desc opacity={0.6} mobileSize={'16px'} style={{ width: '74%' }} >좋아요 요청하기, 해시태그 분석하기, SNS 알고리즘 보기 등의 서비스를 활용하여 SNS 영향력을 키워보세요!</Desc>
          </StepTextWrapper>
          <StepImageWrapper background={'rgba(255, 228, 226, 0.2)'}>
            <ServiceImg src={service} />
          </StepImageWrapper>
        </StepWrapper>

        <StepWrapper>
          <StepImageWrapper background={'linear-gradient(106.05deg, rgba(127, 205, 227, 0.2) 7.34%, rgba(121, 187, 226, 0.2) 38.42%, rgba(114, 166, 223, 0.2) 63.65%, rgba(109, 148, 220, 0.2) 92.93%)'}>
            <FeedbackImg src={feedback} />
          </StepImageWrapper>
          <StepTextWrapper>
            <Text mobileSize={'16px'} opacity={0.4} lineHeight='180%'>Service 03</Text>
            <Text size={'30px'} mobileSize={'20px'} opacity={1} weight={800} lineHeight='140%'>서비스 피드백은</Text>
            <Text size={'30px'} mobileSize={'20px'} opacity={1} weight={800} lineHeight='140%'><Color color={'#379CFA'}>언제든 환영!</Color></Text>
            <Desc opacity={0.6} mobileSize={'16px'} style={{ width: '75%' }} >광고주와의 복잡한 커뮤니케이션 없이 캠페인 제안서를 확인하여 캠페인 진행 여부를 빠르게 결정할 수 있어요</Desc>
          </StepTextWrapper>
        </StepWrapper>
      </StepContainer>

      <Image src={banner} width='100%' top={whetherDesktop?'40px':'0px'} style={{ cursor: 'pointer' }} onClick={() => {
        window.location.href = `https://meercat.io/register_select`
      }} />

    </ComponentWrapper>
  );

}

export default Section04;

const Desc = styled(Text)`
  line-height: 180%;
  margin-top: 50px;

  @media (max-width: 800px){
    margin-top: 30px;
  }

  @media (max-width: 590px){
    margin-top: 20px;
  }
`

const StepContainer = styled.div`
  width: 100%;
  max-width: 1050px;

  display: flex;
  flex-flow: column nowrap;
  gap: 60px;

  @media (max-width: 800px){
    flex-flow: row wrap;
    justify-content: center;
    gap: 30px;
  }
`

const HoneyTipImg = styled.img`
  width: 310px;

  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translate(-50%, 0);

  @media (max-width: 800px){
    width: 250px;
  }
`

const ServiceImg = styled.img`
  width: 456px;
  min-width: 456px;

  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0%);

  @media (max-width: 800px){
    width: 350px;
    min-width: 350px;
  }
`

const FeedbackImg = styled.img`
  width: 318px;

  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0%);

  @media (max-width: 800px){
    width: 250px;
  }
`

const StepWrapper = styled.div`
  width: 100%;
  flex-shrink: 2;

  border: 1px solid #EEEEEE;
  border-radius: 10px;

  display: flex;
  flex-flow: row nowrap;

  @media (max-width: 800px){
    flex-flow: ${props => props.flow ?? 'column nowrap'};
    justify-content: ${props => props.justify ?? ''};
    align-items: ${props => props.align ?? ''};
    width: 100%;
  }
`

const StepTextWrapper = styled.div`
  width: 50%;

  padding-top: 40px;
  padding-bottom: 50px;
  padding-left: 60px;

  display: flex;
  flex-flow: column nowrap;

  position: relative;

  @media (max-width: 800px){
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
}
`

const StepImageWrapper = styled.div`
  width: 50%;
  min-width: 350px;

  background: ${props => props.background ? props.background : ''};

  position: relative;
  overflow: hidden;

  @media (max-width: 800px){
    width: 100%;
    min-width: 100%;
    height: 280px;
  }
`
const Text40 = styled(Text)`
  font-size: 40px;

  @media (max-width: 590px){
    font-size: 20px;
  }
`

const ComponentWrapper = styled.div`
  width: 100%;
  max-width: 1050px;

  padding: ${props => props.padding ? props.padding : '250px 20px 200px'};

  position: relative;

  display: flex;
  flex-flow: ${props => props.flow ? props.flow : ''};
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  align-items: ${props => props.align ? props.align : 'flex-start'};
  gap: ${props => props.gap ? props.gap : ''};

  @media (max-width: 1050px){

    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 590px){
    padding: ${props => props.mobilePadding ? props.mobilePadding : '200px 20px 150px'};
    flex-flow: ${props => props.mobileFlow ? props.mobileFlow : ''};
    justify-content: ${props => props.mobileJustify ? props.mobileJustify : 'flex-start'};
    align-items: ${props => props.mobileAlign ? props.mobileAlign : 'flex-start'};
    gap: ${props => props.mobileGap ? props.mobileGap : ''};
  }
`