import bueaty from 'assets/images/Emoji/beauty.png'
import diet from 'assets/images/Emoji/diet.png'
import fashion from 'assets/images/Emoji/fashion.png'
import television from 'assets/images/Emoji/television.png'
import food from 'assets/images/Emoji/food.png'
import vlog from 'assets/images/Emoji/vlog.png'
import magazine from 'assets/images/Emoji/magazine.png'
import car from 'assets/images/Emoji/car.png'
import drinks from 'assets/images/Emoji/drinks.png'
import sports from 'assets/images/Emoji/sports.png'
import accessories from 'assets/images/Emoji/accessories.png'
import game from 'assets/images/Emoji/game.png'
import society from 'assets/images/Emoji/society.png'
import education from 'assets/images/Emoji/education.png'
import travel from 'assets/images/Emoji/travel.png'
import music from 'assets/images/Emoji/music.png'
import pets from 'assets/images/Emoji/pets.png'
import electronics from 'assets/images/Emoji/electronics.png'
import living from 'assets/images/Emoji/living.png'
import politic from 'assets/images/Emoji/politics.png'
import kids from 'assets/images/Emoji/kids.png'
import movie from 'assets/images/Emoji/movie.png'
import art from 'assets/images/Emoji/art.png'
import etc from 'assets/images/Emoji/etc.png'
import all from 'assets/images/Emoji/all.png'
import hana from 'assets/images/bank/hana.svg'
import ibk from 'assets/images/bank/ibk.svg'
import kb from 'assets/images/bank/kb.svg'
import woori from 'assets/images/bank/woori.svg'
import newTown from 'assets/images/bank/newTown.svg'
import shinhan from 'assets/images/bank/shinhan.svg'
// import bnp from 'assets/images/bank/bnp.svg'
import busan from 'assets/images/bank/busan.svg'
import chinagongsang from 'assets/images/bank/chinagongsang.svg'
import citi from 'assets/images/bank/citi.svg'
import daegu from 'assets/images/bank/daegu.svg'
import gwangju from 'assets/images/bank/gwangju.svg'
import gyeongnam from 'assets/images/bank/gyeongnam.svg'
// import jeju from 'assets/images/bank/jeju.svg'
import jeochook from 'assets/images/bank/jeochook.svg'
import jeonbuk from 'assets/images/bank/jeonbuk.svg'
// import jpmorgan from 'assets/images/bank/jpmorgan.svg'
import kakao from 'assets/images/bank/kakao.svg'
// import kbank from 'assets/images/bank/kbank.svg'
// import kdb from 'assets/images/bank/kdb.svg'
import nh from 'assets/images/bank/nh.svg'
import post from 'assets/images/bank/post.svg'
// import sanrim from 'assets/images/bank/sanrim.svg'
// import sbi from 'assets/images/bank/sbi.svg'
import sc from 'assets/images/bank/sc.svg'
import shinhyeop from 'assets/images/bank/shinhyeop.svg'
import suhyeop from 'assets/images/bank/suhyeop.svg'
// import toss from 'assets/images/bank/toss.svg'
// import boa from 'assets/images/bank/boa.svg'
// import hsbc from 'assets/images/bank/hsbc.svg'

export type ColorSetKey = 'blue' | 'green' | 'yellow' | 'pink' | 'purple' | 'gray';

export const ColorSet = {
    blue: {
        main: '#4480F7',
        mainActive: '#1D5FE1',
        sub: '#E4E9FF',
        subActive: '#BCC5EE',
    },
    green: {
        main: '#2AAE81',
        mainActive: '#068F60',
        sub: '#E8F4EA',
        subActive: '#C8E8CD',
    },
    yellow: {
        main: '#FDB73E',
        mainActive: '#FFA110',
        sub: '#FEE4B3',
        subActive: '#FFD684',
    },
    pink: {
        main: '#FF5171',
        mainActive: '#F12249',
        sub: '#FFE1E3',
        subActive: '#FFC6CA',
    },
    purple: {
        main: '#665AA6',
        mainActive: '#4633AC',
        sub: '#ECE8FF',
        subActive: '#DCD6FF',
    },
    gray: {
        main: '#5C676B',
        mainActive: '#2D383C',
        sub: '#EBECF0',
        subActive: '#DADDE6',
    },
}

export const ButtonColorSet = {
    prev:{
        main:'#FF543E',
        mainActive: '#DF301A'
    },
    next:{
        main:'#0050FF',
        mainActive: '#0048E5'
    },
    disabled:{
        main: '#E6E6E6',
        mainActive: 'rgb(0, 0, 0, 0.2)'
    },
    none:{
        main: 'white',
        mainActive: 'white'
    }
}

export type SocialMediaKey = "instagram" | "youtube" | "tiktok";

export const SocialMediaName: Record<SocialMediaKey, string> = {
    instagram: "인스타그램",
    youtube: "유튜브",
    tiktok: "틱톡",
} as const;

export const DAY = ["일", "월", "화", "수", "목", "금", "토"];

export type useChannel = 'etc' | 'instagram' | 'facebook' | 'youtube';

export const NecessaryUrl = {
    instagram : 'instagram.com',
    facebook : 'facebook.com',
    youtube : 'youtube.com',
    etc : '',
}

export const UseChannelRegisterName = {
    instagram : 'useInstagram',
    facebook : 'useFacebook',
    youtube : 'useYoutube',
    etc : 'useEtc',
} as const;

export const UseChannelNames = {    // for rendering
    instagram: '인스타그램',
    facebook: '페이스북',
    youtube: '유튜브',
    etc: '기타',
} as const;

export const UseChannelKeyNames = {
    '인스타그램' : 'instagram',
    '페이스북' : 'facebook',
    '유튜브' : 'youtube',
    '기타' : 'etc',
} as const;

export const UseChannelFormNames = {
    instagram: 'instagram_reference',
    facebook: 'facebook_reference',
    youtube: 'youtube_reference',
    etc: 'etc_reference',
}

export const FormKey = {
    /* 필수 필드 */
    media: "media",
    dates: "dates",
    creatorId: "participant-beneficiary/-id/",
    calendarType: "calendarType",
    purpose: "purpose",
    type: "campaign-type/",
    category: "campaign-category/",
    usePlan: "campaign-derived_plan/-period/",
    useChannel: "campaign-derived_plan/-channels/",
    useChannelCustom: "campaign-derived_plan/-custom_channel/",
    useChannelReference: "campaign-derived_plan/-reference/",
    offerPrice: "campaign-offer_price/",
    downpayment: "campaign-option/-downpayment/",
    brand: "campaign-brand/",
    campaignName: "campaign-name/",
    unitPrice: "campaign-unit_price/",
    description: "description",
    campaignConceptName: "campaign-concept/-name/",
    exposeList: "campaign-item/-name/",
    apealPoint: "campaign-item/-point/",
    apealMethod: "campaign-item/-how/",
    angle: "campaign-item/-angle/",
    precaution: "precaution",
    referenceUrl: "reference",
    attachments: "attachments",
    sponsorEmail: "participant-sponsor/-email/",
    sponsorName: "participant-sponsor/-name/",
    sponsorPhoneNumber: "participant-sponsor/-phone/",
    ceoName: 'participant-sponsor/-ceo_name/',
    businessNum: 'participant-sponsor/-business_number/',
    startDate: 'participant-sponsor/-start_date/',
    businessType: 'participant-sponsor/-business_type/',
    businessTaxType: 'participant-sponsor/-business_tax_type/',
    sponsorBrand: 'participant-sponsor/-brand/',
    uuid: "challenge",

    /* 옵션 필드 */
    format: "campaign-option/-format/",
    needRunningTime: "needRunningTime",
    runningMin: "runningMin",
    runningSec: "runningSec",
    runningTime: "campaign-option/-running_time/",
    lifetime: "campaign-option/-lifetime/",
    allotedTime: "campaign-option/-alloted_day/-times/",
    editOpportunity: "campaign-option/-edit_opportunity/",
    expense: "campaign-option/-expense/",
    deposit: "campaign-option/-deposit/",
    sponsorAddress: "campaign-option/-visit_address/",
    sponsorAddressInput1: "campaign-option/-visit_address/-input1/",
    sponsorAddressInput2: "campaign-option/-visit_address/-input2/",
    instagramPhotoCount: "campaign-option/-photo_count/",
    pastContractId: "previous_id",
    //   creatorEmail: "email-beneficiary/",

    /* 협의하기 특수 필드 */
    need: 'need',
} as const;

export type FormNames = 'media' | 'dates' | 'creatorId' | 'calendarType' | 'purpose' | 'type' | 'category' | 'usePlan' | 'useChannel' | 'useChannelCustom' | 'useChannelReference' | 'offerPrice' | 'brand' | 'campaignName' | 'unitPrice' | 'description' | 'referenceUrl' | 'attachments' | 'sponsorEmail' | 'sponsorName' | 'sponsorPhoneNumber' | 'ceoName' | 'businessNum' | 'startDate' | 'businessType' | 'businessTaxType' | 'sponsorBrand' | 'uuid' | 'format' | 'runningMin' | 'runningSec' | 'runningTime' | 'lifetime' | 'allotedTime' | 'editOpportunity' | 'expense' | 'deposit' | 'sponsorAddress' | 'sponsorAddressInput1' | 'sponsorAddressInput2' | 'instagramPhotoCount' | 'pastContractId'

export const DisableReason = {
    default: `아직 모든 항목을 
    완료하지 않았어요`,
    campaignType: '캠페인 분류를 선택해 주세요',
    campaignCategory: '캠페인 형태를 선택해 주세요',
    campaignFormat: '업로드 형태를 선택해 주세요',
    photoCount: '업로드 장수를 입력해 주세요',
    editOpportunity: '수정 횟수를 선택해 주세요',
    runningTime: '소구하는 러닝타임을 입력해 주세요',
    allotedTime: '캠페인 일정을 선택해 주세요',
    usePlan: '2차 활용 계획을 선택해 주세요',
    useChannel: '2차 활용 채널을 선택해 주세요',
    useChannelReference: '선택하신 2차 활용 채널의 URL을 입력해 주세요',
    useChannelReferenceType: '2차 활용 채널에 입력하신 URL이 옳지 않은 형식이에요',
    expense: '활동 지원 비용을 입력해 주세요',
    offerPrice: '제안 금액을 입력해 주세요'
} as const;

export type validMatcherKey = 'uuid' | 'email' | 'emailCode' | 'url' | 'unixTime' | 'unixTimeStamp' | 'mailClaim' | 'phoneNumber' | 'phoneNumberStroke' | 'name' | 'nameStroke' | 'nickname' | 'nicknameStroke' | 'campaignName' | 'campaignNameStroke' | 'creatorId' | 'creatorIdStroke' | 'creatorPassword' | 'bankName' | 'bankNumber' | 'bankNumberStroke' | 'resident' | 'businessNumber' | 'businessNumberStroke' | 'businessSince' | 'businessSinceStroke' | 'selfValue' | 'editOpportunity' | 'photoCount' | 'runningTime' | 'description' | 'editChannel' | 'ppl' | 'unitPrice' | 'address' | 'brand' | 'brandStroke' | 'phoneAuthCode';

export type DisableReasonKey = "default" | "campaignType" | "campaignCategory" | "campaignFormat" | "photoCount" | "editOpportunity" | "runningTime" | "allotedTime" | "usePlan" | "useChannel" | "useChannelReference" | "useChannelReferenceType" | "expense" | "offerPrice";

export const Emoji = {
    "뷰티": bueaty,
    "다이어트": diet,
    "패션": fashion,
    "TV 프로그램": television,
    "음식": food,
    "브이로그": vlog,
    "화보": magazine,
    "자동차": car,
    "주류": drinks,
    "운동": sports,
    "액세서리": accessories,
    "게임": game,
    "사회/종교": society,
    "교육/경제": education,
    "해외 여행": travel,
    "음악": music,
    "반려동물": pets,
    "IT 전자제품": electronics,
    "홈 리빙": living,
    "시사/정치": politic,
    "키즈": kids,
    "영화/애니": movie,  
    "예술": art,
    "기타": etc,
    "모두 받기": all
}

export type EmojiKeys = '뷰티' | '다이어트' | '패션' | 'TV 프로그램' | '음식' | '브이로그' | '화보' | '자동차' | '주류' | '운동' | '액세서리' | '게임' | '사회/종교' | '교육/경제' | '해외 여행' | '음악' | '반려동물' | 'IT 전자제품' | '홈 리빙' | '시사/정치' | '키즈' | '영화/애니' | '예술' | '기타' | '모두 받기';

export const BankFormat = {
    '농협': {
        name: '농협은행',
        key: '농협은행',
        format: '###-####-####-##',
        length: 13,
        code: '011',
        icon: nh
    },
    'KB국민': {
        name: '국민은행',
        key: '국민은행',
        format: '######-##-######',
        length: 14,
        code: '004',
        icon: kb
    },
    '카카오뱅크': {
        name: '카카오뱅크',
        key: '카카오뱅크',
        format: '####-##-#######',
        length: 13,
        code: '090',
        icon: kakao
    },
    '신한': {
        name: '신한은행',
        key: '신한은행',
        format: '###-###-######',
        length: 12,
        code: '088',
        icon: shinhan
    },
    '우리': {
        name: '우리은행',
        key: '우리은행',
        format: '####-###-######',
        length: 13,
        code: '020',
        icon: woori
    },
    'IBK기업': {
        name: '기업은행',
        key: '기업은행',
        format: '###-######-##-###',
        length: 14,
        code: '003',
        icon: ibk
    },
    '하나': {
        name: '하나은행',
        key: 'KEB하나은행',
        format: '###-######-#####',
        length: 14,
        code: '081',
        icon: hana
    },
    '새마을': { // 최신화 필요
        name: '새마을은행',
        key: '새마을금고중앙회',
        format: '####-##-######-#',
        length: 13,
        code: '045',
        icon: newTown
    },
    '대구': {
        name: '대구은행',
        key: '대구은행',
        format: '###-##-######-#',
        length: 12,
        code: '031',
        icon: daegu
    },
    '부산': {
        name: '부산은행',
        key: '부산은행',
        format: '###-####-####-##',
        length: 13,
        code: '032',
        icon: busan
    },
    '경남': {   // 최신화 필요
        name: '경남은행',
        key: '경남은행',
        format: '###-##-######',
        length: 11,
        code: '039',
        icon: nh
    },
    '우체국': { // 최신화 필요
        name: '우체국은행',
        key: '우체국',
        format: '######-##-######',
        length: 14,
        code: '071',
        icon: post
    },
    '신협': {   // 최신화 필요
        name: '신협은행',
        key: '신협중앙회',
        format: '####-###-######',
        length: 13,
        code: '048',
        icon: shinhyeop
    },
    'SC제일': {
        name: 'SC제일은행',
        key: 'SC제일은행',
        format: '###-##-######',
        length: 11,
        code: '023',
        icon: sc
    },
    '수협': {   // 최신화 필요
        name: '수협은행',
        key: '수협은행',
        format: '###-##-########-#',
        length: 14,
        code: '007',
        icon: suhyeop
    },
    '광주': {   // 최신화 필요
        name: '광주은행',
        key: '광주은행',
        format: '###-####-###',
        length: 10,
        code: '034',
        icon: gwangju
    },
    '전북': {   // 최신화 필요
        name: '전북은행',
        key: '전북은행',
        format: '####-###-######',
        length: 13,
        code: '037',
        icon: jeonbuk
    },
    '저축은행': {   // 최신화 필요
        name: '저축은행',
        key: 'SBI 저축은행',
        format: '####-###-######',
        length: 13,
        code: '103',
        icon: jeochook
    },
    '중국공상': {   // 최신화 필요
        name: '중국공상',
        key: '중국공상은행',
        format: '###-#########-##',
        length: 14,
        code: '062',
        icon: chinagongsang
    },
    '씨티': {
        name: '씨티은행',
        key: '한국씨티은행',
        format: '###-######-###',
        length: 12,
        code: '027',
        icon: citi
    },
} as const

export const registerKey = ['agreeAll', 'usageAgree', 'policyAgree', 'marketingAgree', 'businessType', 'businessName', 'businessNum', 'businessSince', 'id', 'password', 'nickName', 'birth', 'gender', 'phone', 'phoneAuthSuccess', 'email', 'emailAuthSuccess', 'token', 'instagram_code', 'isInstagramActive', 'youtube_code', 'isYoutubeActive', 'concept', 'pageColor', 'price', 'min', 'max', ]

export const campaignStatusColor = {
    arrive: {
        main: '#FFC121',
        sub: 'rgba(255, 193, 33, 0.1)'
    },
    ongoing: {
        main: '#4480F7',
        sub: 'rgba(68, 128, 247, 0.1)'
    },
    active: {
        main: '#4480F7',
        sub: 'rgba(68, 128, 247, 0.1)'
    },
    content: {
        main: '#4F5CD9',
        sub: 'rgba(79, 92, 217, 0.1)'
    },
    done: {
        main: '#2AAE81',
        sub: 'rgba(79, 92, 217, 0.1)'
    },
    halt: {
        main: 'rgba(247, 68, 68, 1)',
        sub: 'rgba(247, 68, 68, 0.1)'
    },
    none: {
        main: '#FFFFFF',
        sub: '#F6F6F6'
    },
}

export const campaignFormat = {
    all: {
      name: '모든 캠페인',
      campaignType: 'latest',
    },
    new: {
      name: '제안 받음',
      campaignType: 'latest_start',
    },
    attract: {
      name: '협의 중',
      campaignType: 'latest_ing',
    },
    matched: {
      name: '진행 중',
      campaignType: 'latest_ing',
    },
    complete: {
        name: '완료됨',
        campaignType: 'settlement',
    },
    stop: {
      name: '중단됨',
      campaignType: 'latest_end',
    },
  }

  export const SnsNames = {
    youtube: 'YouTube',
    instagram: 'Instagram'
  }

  export const BUTTONTYPE = {
    next: {
        disabled: {
            normal: {
                background: '#FFFFFF',
                border: '#EEEEEE',
                color: '#AAAAAA',
            },
            effect: {
                background: '#F6F6F6',
                border: '#EEEEEE',
                color: '#AAAAAA',
            }
        },
        active: {
            normal: {
                background: '#0050FF',
                border: '#0050FF',
                color: '#FFFFFF',
            },
            effect: {
                background: '#0048E5',
                border: '#0048E5',
                color: '#FFFFFF',
            }
        }
    },
    nextLight: {
        disabled: {
            normal: {
                background: '#FFFFFF',
                border: '#EEEEEE',
                color: '#AAAAAA',
            },
            effect: {
                background: '#F6F6F6',
                border: '#EEEEEE',
                color: '#AAAAAA',
            }
        },
        active: {
            normal: {
                background: '#ECF2FE',
                border: '#ECF2FE',
                color: '#4480F7',
            },
            effect: {
                background: '#ECF2FE',
                border: '#ECF2FE',
                color: '#4480F7',
            }
        }
    },
    prev: {
        disabled: {
            normal: {
                background: '',
                border: '',
                color: '',
            },
            effect: {
                background: '',
                border: '',
                color: '',
            }
        },
        active: {
            normal: {
                background: '',
                border: '',
                color: '',
            },
            effect: {
                background: '',
                border: '',
                color: '',
            }
        }
    },
}

export const ZINDEX = {
    layout: 3,
    prior: 1,
    topPrior: 2,
    popup: {
        content: 5,
        background: 4,
        small: {
            content: 7,
            background: 6
        }
    },
    toast: 10
}

export const CAMPAIGN = {
    instagram: {
        format: [
            {
                name: '피드',
                value: '피드'
            },
            {
                name: '스토리',
                value: '스토리'
            },
            {
                name: '릴스',
                value: '릴스'
            },
            {
                name: '라이브',
                value: '라이브'
            },
        ]
    },
    youtube: {
        format: [
            {
                name: '일반 영상',
                value: '일반 영상'
            },
            {
                name: 'Shorts',
                value: 'Shorts'
            },
            {
                name: '라이브',
                value: '라이브'
            },
        ]
    },
    common: {
        type: [
            { name: '온라인', value: '온라인', tippy: `예시) 배송 제품 리뷰, 온라인 서비스 리뷰`},
            { name: '오프라인', value: '오프라인', tippy: `예시) 방문 리뷰` },
        ],
        category: [
            { name: '브랜디드 PPL', value: '브랜디드 PPL', tippy: `제품 소개만을 위한 컨텐츠를 제작하는 PPL` },
            { name: '표준 PPL', value: '표준 PPL', tippy: '컨텐츠 중간에 제품을 소개하는 PPL' },
            { name: '단순 PPL', value: '단순 PPL' , tippy: `컨텐츠 안에서 자연스럽게 제품을 노출하는 PPL` },
        ],
        needRunningTime: [
            { 
                name: '무관',
                value: '무관' 
            },
            { 
                name: '유관',
                value: '유관' 
            },
        ],
        needPhotoCount: [
            { 
                name: '무관',
                value: '무관' 
            },
            { 
                name: '유관',
                value: '유관' 
            },
        ],
        editOpportunity: [
            { name: '1회', value: '1' },
            { name: '2회', value: '2' }
        ],
        lifetime: [
            { name: '1개월', value: 1 },
            { name: '3개월', value: 3 },
            { name: '6개월', value: 6 }
        ],
        usePlan: [
            { name: '없음', value: '없음' },
            { name: '1개월', value: '1' },
            { name: '2개월', value: '2' }
        ],
        useChannel: [
            { name: '인스타그램', value: 'instagram' },
            { name: '페이스북', value: 'facebook' },
            { name: '유튜브', value: 'youtube' },
            { name: '기타', value: 'etc' },
        ]
    }
}

export const SENDERTYPENAME = {
    CREATOR: '크리에이터',
    SPONSOR: '광고주'
}

Object.freeze([ZINDEX, BUTTONTYPE, CAMPAIGN, SENDERTYPENAME])

export const INVALIDID = [
    'creatorly',
    'creator',
    'meercat',
    'awakecorp',
    'awakecorporation',
    'awaker',
    'kiu',
    'kiu_teacher',
    'kiudesign',
    'suetv',
    'soobingsootv',
    'sue',
    'bingsoobing',
    'spaak',
    'tamgu',
    'tylor',
    'tylerrasch1',
    'influencer',
    'influencers',
    'global',
    'asia',
    'korea',
    'ceo',
    'coo',
    'awaker',
    'dean',
    'juno',
    'move',
    'reo',
    'aiden',
    'paul'
  ]

  export const WEB_VIEW = {
    KAKAO: 'KAKAOTALK',
    INSTAGRAM: 'Instagram',
    NAVER: 'NAVER',
    SNAPCHAT: 'Snapchat',
    LINE: 'Line',
    FACEBOOK: 'FB',
    IN_APP: 'inapp'
  }