import { Cookies } from "react-cookie";

const reactCookie = new Cookies()

const Cookie = {
    set : function(name, value, option){

        if(reactCookie.get(name)){

            reactCookie.remove(name)
        }

        return reactCookie.set(name, value, {...option})
    },
    get : function(name){
        
        return reactCookie.get(name)
    },
    remove : function(name, option){

        return reactCookie.remove(name, {...option})
    }
}

export default Cookie