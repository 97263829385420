
//React
import React, { Suspense, createContext, useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4"
import { CookiesProvider } from 'react-cookie';

//Pages
import ContactPage from "pages/Creator/Contact";
import SponsorContact from "pages/Sponsor/Contact";
import BankRegisteration from "pages/Creator/BankRegistration";

//components
import WrongUrl from 'pages/WrongUrl';
import SponsorFulfillment from "pages/Sponsor/Fulfillment";
import CreatorProfile from "pages/CreatorProfilePage";
import Login from "pages/Login";
import Join from "pages/Join";
import PrivateRoute from "modules/Credential/components/PrivateRoute";
import PublicRoute from "modules/Credential/components/PublicRoute";

//hook
import useToast from "hook/useToast";
import useSmallPopup from "hook/useSmallPopup";
import useStandardPopup from "hook/useStandardPopup";
import useMaintenancePopup from "modules/Firebase/hooks/useMaintenancePopup";
import useRemoteConfig from "modules/Firebase/hooks/useRemoteConfig";

//types
import useCredential from "modules/Credential/hooks/useCredential";

//Context
import CredentialContext from "modules/Credential/context";
import Home from "pages/Tutorial/Analysis";

export const GlobalContext = createContext<{
  handleToast?: ReturnType<typeof useToast>['handleToast'];
  handlePopup?: ReturnType<typeof useSmallPopup>['handlePopup'];
  handleStandardPopup?: ReturnType<typeof useStandardPopup>['handleStandardPopup'];
}>({})

const Service = React.lazy(() => import('pages/Service'));
const Ceo = React.lazy(() => import('pages/Ceo'));
const Privacy = React.lazy(() => import('pages/Privacy'));
const LandingPage = React.lazy(() => import("pages/LandingPage"));
const SendContract = React.lazy(() => import('pages/Sponsor/SendContract'));
const Tutorial = React.lazy(() => import('pages/Tutorial'));
const CheckContract = React.lazy(() => import('pages/CheckContract'));
const Application = React.lazy(() => import('pages/Application'));
const Integrate = React.lazy(() => import("pages/Integrate"));
const AwakeCorp = React.lazy(() => import('pages/AwakeCorp'));


declare global {
  interface Window {
    ChannelIO: any
  }
}

const App = function () {
  const { credentials, validity, userData, avatarData, updateCredential, discardCredential } = useCredential()
  const { remoteConfig } = useRemoteConfig()
  const { handleToast, component: Toast } = useToast()
  const { handlePopup, component: Popup } = useSmallPopup()
  const { handleStandardPopup, component: StandardPopup } = useStandardPopup()
  const { handleMaintenancePopup, component: MaintenancePopup } = useMaintenancePopup();

  useEffect(function(){ // GA initializer

    ReactGA.initialize("G-WTS77LPV7J");
  }, [])

  useEffect(() => { // RemoteConfig initializer
    if(remoteConfig.maintenanceActive) {
      handleMaintenancePopup.set({
        startTime: remoteConfig.maintenanceStarttime!,
        endTime: remoteConfig.maintenanceEndtime!,
        button: {
          desc: '고객센터',
          isActive: true,
          onClick: function () {
            window.ChannelIO('showMessenger')
          }
        }
      })
    }

  }, [remoteConfig])

  return (
    <CredentialContext.Provider value={{credentials, validity, userData, avatarData, updateCredential, discardCredential}}>
      <GlobalContext.Provider value={{ handleToast, handlePopup, handleStandardPopup }}>
        <CookiesProvider>
          <Suspense fallback={null}>
            <Routes>
              <Route path='/' element={<PublicRoute><Home /></PublicRoute>} />
              <Route path='/tutorial/*' element={<Tutorial />} />
              <Route path='/team' element={<AwakeCorp />} />
              <Route path='/ceo' element={<Ceo />} />
              <Route path="/policy" element={<Service />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/none" element={<WrongUrl />} />
              <Route path="*" element={<WrongUrl />} />
              {/* <Route path='/login/*' element={<PublicRoute><Login /></PublicRoute>} />
              <Route path='/join/*' element={<PublicRoute><Join /></PublicRoute>} /> */}
              {/* <Route path='/integrate/*' element={<Integrate />} /> */}
              {/* <Route path="/app/*" element={<PrivateRoute><Application /></PrivateRoute>} />
              <Route path='/@:creatorId/' element={<CreatorProfile />} />
              <Route path='/@:creatorId/biz/*' element={<SendContract />} />

              <Route path='/bank_registration' element={<BankRegisteration />} />

              <Route path="/negotiation_table/start/*" element={<ContactPage />} />
              <Route path="/negotiation_table/end" element={<SponsorContact />} />
              <Route path="/negotiation_table/*" element={<CheckContract />} /> */}


              {/* <Route path='/sponsor/contracts/:contractId/fulfillment' element={<SponsorFulfillment />} /> */}
            </Routes>
            {Toast}
            {Popup}
            {StandardPopup}
            {MaintenancePopup}
          </Suspense>
        </CookiesProvider>
      </GlobalContext.Provider>
    </CredentialContext.Provider>
  )
};

export default App;