export const TUTORIAL_URL = {
    PROPOSAL: 'https://creator.ly',
    COMPROMISE: 'https://creator.ly',
    SIGNING: 'https://creator.ly',
    ESCROW: 'https://creator.ly',
    CREATION: 'https://creator.ly',
    UPLOAD: 'https://creator.ly',
    CALCULATE: 'https://creator.ly'
}

export const CHANNEL_TALK = 'https://creatorly.channel.io/lounge'

export const RECRUIT_WEBSITE = 'https://awakecorp.career.greetinghr.com/'