import { useLayoutEffect, useEffect, useState } from 'react'

import { RemoteConfigItem } from "modules/Firebase/remote-config.enum";
import { initializeApp } from "firebase/app";
import { fetchAndActivate, getBoolean, getRemoteConfig, getString } from "firebase/remote-config";
import ms from "ms";
import { RemoteConfigType } from "modules/Firebase/remote-config.context";

function useRemoteConfig() {
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfigType>({});

  useLayoutEffect(() => {
    const firebaseApp = initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    })

    const remoteConfig = getRemoteConfig(firebaseApp);

    remoteConfig.settings.fetchTimeoutMillis = ms('3s');
    remoteConfig.settings.minimumFetchIntervalMillis = ms('1m');

    remoteConfig.defaultConfig = {
      [RemoteConfigItem.MAINTENANCE_ACTIVE]: false,
      [RemoteConfigItem.MAINTENANCE_STARTTIME]: new Date().toString(),
      [RemoteConfigItem.MAINTENANCE_ENDTIME]: new Date(new Date().getTime() + ms('3h')).toString(),
    } as Record<RemoteConfigItem, string | number | boolean>;

    fetchAndActivate(remoteConfig).finally(() => {
      setRemoteConfig({
        maintenanceActive: getBoolean(remoteConfig, 'MAINTENANCE_ACTIVE'),
        maintenanceStarttime: new Date(getString(remoteConfig, 'MAINTENANCE_STARTTIME')),
        maintenanceEndtime: new Date(getString(remoteConfig, 'MAINTENANCE_ENDTIME')),
      });
    })
  }, [])

  return { remoteConfig }
}

export default useRemoteConfig