import styled from "styled-components";

const TextDiv = styled.div<{
    size?: string | number,
    sizeMobile?: string | number,
    weight?: string | number,
    weightMobile?: string | number,
    color?: string | number,
    colorMobile?: string | number,
    lineHeight?: string | number,
    lineHeightMobile?: string | number,
    opacity?: string | number,
    opacityMobile?: string | number,
}>`
    font-size: ${props => props.size ? props.size : '16px'};
    color: ${props => props.color ? props.color : '#222222'};
    font-weight: ${props => props.weight ? props.weight : 400};
    line-height: ${props => props.lineHeight ? props.lineHeight : '130%'};
    
    opacity: ${props => props.opacity ? props.opacity : '1'};

    @media (max-width: 590px){
        font-size: ${props => props.sizeMobile ? props.sizeMobile : props.size ? props.size : '16px'};
        color: ${props => props.colorMobile ? props.colorMobile : props.color ? props.color : '#222222'};
        font-weight: ${props => props.weightMobile ? props.weightMobile : props.weight ? props.weight : 400};
        line-height: ${props => props.lineHeightMobile ? props.lineHeightMobile : props.lineHeight ? props.lineHeight : '130%'};
        
        opacity: ${props => props.opacityMobile ? props.opacityMobile : props.opacity ? props.opacity : '1'};
    }
`

export const BreakText = styled.div<{
    width?: string | number,
    widthMobile?: string | number,
    size?: string | number,
    sizeMobile?: string | number,
    weight?: string | number,
    weightMobile?: string | number,
    color?: string | number,
    colorMobile?: string | number,
    lineHeight?: string | number,
    lineHeightMobile?: string | number,
    opacity?: string | number,
    opacityMobile?: string | number,
}>`
    max-width: ${props => props.width ? props.width : '100%'};
    font-size: ${props => props.size ? props.size : '16px'};
    color: ${props => props.color ? props.color : '#222222'};
    font-weight: ${props => props.weight ? props.weight : 400};
    line-height: ${props => props.lineHeight ? props.lineHeight : '130%'};
    
    opacity: ${props => props.opacity ? props.opacity : '1'};

    @media (max-width: 590px){
        max-width: ${props => props.widthMobile ? props.widthMobile : props.width ? props.width : '100%'};
        font-size: ${props => props.sizeMobile ? props.sizeMobile : props.size ? props.size : '16px'};
        color: ${props => props.colorMobile ? props.colorMobile : props.color ? props.color : '#222222'};
        font-weight: ${props => props.weightMobile ? props.weightMobile : props.weight ? props.weight : 400};
        line-height: ${props => props.lineHeightMobile ? props.lineHeightMobile : props.lineHeight ? props.lineHeight : '130%'};
        
        opacity: ${props => props.opacityMobile ? props.opacityMobile : props.opacity ? props.opacity : '1'};
    }

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const FlexDiv = styled.div<{
    flow?: string | number,
    flowMobile?: string | number,
    justify?: string | number,
    justifyMobile?: string | number,
    align?: string | number,
    alignMobile?: string | number,
    gap?: string | number,
    gapMobile?: string | number,
}>`
    display: flex;
    flex-flow: ${props => props.flow ? props.flow : 'row wrap'};
    justify-content: ${props => props.justify ? props.justify : ''};
    align-items: ${props => props.align ? props.align : ''};
    gap: ${props => props.gap ? props.gap : ''};

    @media (max-width: 590px){
        flex-flow: ${props => props.flowMobile ? props.flowMobile : props.flow ? props.flow : 'row wrap'};
        justify-content: ${props => props.justifyMobile ? props.justifyMobile : props.justify ? props.justify :  ''};
        align-items: ${props => props.alignMobile ? props.alignMobile : props.align ? props.align : ''};
        gap: ${props => props.gapMobile ? props.gapMobile : props.gap ? props.gap : ''};
    }
`

const Span = styled.span<{
    size?: string | number,
    sizeMobile?: string | number,
    weight?: string | number,
    weightMobile?: string | number,
    color?: string | number,
    colorMobile?: string | number,
    opacity?: string | number,
    opacityMobile?: string | number,
}>`
    font-size: ${props => props.size ? props.size : ''};
    color: ${props => props.color ? props.color : ''};
    font-weight: ${props => props.weight ? props.weight : ''};
    line-height: 130%;
    
    opacity: ${props => props.opacity ? props.opacity : '1'};

    @media (max-width: 590px){
        font-size: ${props => props.sizeMobile ? props.sizeMobile : props.size ? props.size : ''};
        color: ${props => props.colorMobile ? props.colorMobile : props.color ? props.color : ''};
        font-weight: ${props => props.weightMobile ? props.weightMobile : props.weight ? props.weight : ''};
        line-height: 130%;
        
        opacity: ${props => props.opacityMobile ? props.opacityMobile : props.opacity ? props.opacity : ''};
    }
`

export { TextDiv, Span, FlexDiv }