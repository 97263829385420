const loginRedirect = function(){
    const redirectTarget = new URL(window.location.href).searchParams.get('redirect_target') ?? window.sessionStorage.getItem('redirect_target')    // 로그인 이후 redirect target

    if(!redirectTarget){

        window.history.replaceState({}, '', `${window.location.origin}/app/campaign`)
        window.location.replace(`${window.location.origin}/app/campaign`)

    }else if(redirectTarget.includes(window.location.origin)){  // internal url
        const path = redirectTarget.replace(window.location.origin, '');

        window.sessionStorage.removeItem('redirect_target')
        window.history.replaceState({}, '', `${window.location.origin}${path}`)
        window.location.replace(`${window.location.origin}${path}`)

    }else{  // external url

        window.sessionStorage.removeItem('redirect_target')
        window.history.replaceState({}, '', `${redirectTarget}`)
        window.location.replace(redirectTarget)
    }
}

export default loginRedirect