import { MutableRefObject, useMemo } from "react";
import styled from "styled-components";

//comonents
import { FlexDiv, TextDiv } from "components/System/Tools";

//Types
import { ZINDEX } from "../../../../types/common";
import Button from "components/System/Button";
import { twoDigits } from "utils/twoDigits";

//assets
import maintenance_meercat from 'assets/Announce/maintenance_meercat.svg'

export interface MaintenancePopupProps {
    popupRef: MutableRefObject<HTMLDivElement | null>;
    startTime: Date;
    endTime: Date;
    button: Object;
}

const DAY = [
    '일',
    '월',
    '화',
    '수',
    '목',
    '금',
    '토'
]

export default function MaintenancePopup({
    popupRef,
    startTime,
    endTime,
    button,
}: MaintenancePopupProps) {
    const maintenanceTime = () => {
        const startDate = new Date(startTime)
        const endDate = new Date(endTime)
        const differentDate = (
            startDate.getFullYear() !== endDate.getFullYear() ||
            startDate.getMonth() !== endDate.getMonth() ||
            startDate.getDate() !== endDate.getDate()
        )

        if(differentDate){

            return `${twoDigits(startDate.getMonth() + 1)}월 ${twoDigits(startDate.getDate())}일(${DAY[startDate.getDay()]})~${twoDigits(endDate.getMonth() + 1)}월 ${twoDigits(endDate.getDate())}일(${DAY[endDate.getDay()]})`
        }

        return `${startDate.getFullYear()}년 ${twoDigits(startDate.getMonth() + 1)}월 ${twoDigits(startDate.getDate())}일(${DAY[startDate.getDay()]}) ${startDate.getHours()}:${twoDigits(startDate.getMinutes())}~${endDate.getHours()}:${twoDigits(endDate.getMinutes())}`
    }

    return (
    <>
        <PopupContainer ref={popupRef}>
            <ManipulateWrapper>
                <div></div>
                <div></div>
            </ManipulateWrapper>
            <ContentWrapper>
                <TitleSection>
                    <TextDiv size={'22px'} color={'#111111'} weight={700}>서비스 업데이트 안내</TextDiv>
                    <TextDiv size={'16px'} color={'#555555'} lineHeight={'25px'}>개선된 서비스 이용을 위하여 서비스 이용이 일시 중단되오니 이용에 참고하여 주시길 바랍니다</TextDiv>
                </TitleSection>
                <ContentSection>
                    <FlexDiv  flow={'column nowrap'} align={'center'} gap={'6px'} style={{width: '100%', padding: '15px 20px', background: '#ECF2FE', borderRadius: 5, position: 'relative'}}>
                        <TextDiv size={'16px'} color={'#0050FF'} weight={700}>{maintenanceTime()}</TextDiv>
                        <TextDiv size={'12px'} color={'#777777'} style={{maxWidth: 200}}>소요 시간은 진행 상황에 따라 단축되거나 지연될 수있는 점 양해 부탁드립니다.</TextDiv>
                        <div style={{minWidth: 20, minHeight: 20, background: '#ECF2FE', position: 'absolute', bottom: '-10px', transform: 'rotate(45deg)' }} />
                    </FlexDiv>
                    <img src={maintenance_meercat} />
                </ContentSection>
            </ContentWrapper>
            <ButtonWrapper>
                <Button button={button}/>
            </ButtonWrapper>
        </PopupContainer>
        <PopupBackground />
    </>
    )
}

const PopupContainer = styled.section`
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 570px;

    padding: 0px 30px 30px 30px ;

    background: white;
    border-radius: 5px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: ${ZINDEX.popup.content};

    @media (min-width: 591px){

        animation: contentpopup_show 0.1s 1;

        @keyframes contentpopup_show {
            0%{
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.98);
            }
            98%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1.002);
            }
            100%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
        }

        @keyframes contentpopup_hide {
            0%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
            98%{
                opacity: 1;
                transform: translate(-50%, -50%) scale(1.002);
            }
            100%{
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.98);
            }
        }

    }


    @media (max-width: 590px){
        min-width: 100vw;
        min-height: 100%;
        max-height: 100%;
        
        padding: 0px 20px 30px 20px;

        background: white;
        border-radius: 0px;
        
        position: fixed;
        top: 0px;
        left: 0px;
        transform: translate(0%, 0%);

        animation: show_up_mobile 0.4s 1 ease;
    }
`

const ManipulateWrapper = styled.div`
    width: 100%;
    height: 70px;

    padding: 0px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 590px){
        width: 100vw;
    }
`

const PopupBackground = styled.div`
    width: 100%;
    height: 100%;

    background: rgb(0, 0, 0, 0.6);

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    user-select: all;

    z-index: ${ZINDEX.popup.background};

    @media (max-width: 590px){
        top: 50%;
        left: 50%;
        padding: 40px 20px 90px 20px;        
    }
`

const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    margin: 1rem 0;
    
    text-align: start;
`;

const ContentSection = styled.section`
    width: 100%;
    
    text-align: center;
    
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    
`;

const ContentWrapper = styled.div`
    width: 100%;
    height: calc(100% - 80px - 60px);
    max-height: calc(100% - 80px - 60px);
    
    margin-bottom: 20px;

    display: flex;
    flex-flow: column nowrap;
    gap: 20px;

    overflow: hidden;
`

const ButtonWrapper = styled.div`
    width: 100%;
    min-height: 60px;
`