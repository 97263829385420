import { useState, useEffect } from 'react';

const useMediaQuery = function (breakpoint) {

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(function () {

    const handleResize = function () {

      setInnerWidth(window.innerWidth);

    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return function () {

      window.removeEventListener('resize', handleResize);

    }

  }, [])

  if (innerWidth > breakpoint) {

    return true;

  } else {

    return false;

  }

};

export default useMediaQuery;
