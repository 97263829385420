//3rd party
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import qs from "qs";

//Chore
import { FormKey } from "types/common";
import { OFFER_PRICE } from "types/business";

//Default settings
const unixTime = new Date().getTime();
const humanTime = new Date;

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_PROXY,
  responseType: "json",
  withCredentials: false,
  timeout: 10000,
});

const authAxios = Axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_PROXY,
  responseType: "json",
  withCredentials: false,
  timeout: 10000,
})

const meercatAxios = Axios.create({
  baseURL: process.env.REACT_APP_MEERCAT_API_PROXY,
  responseType: "json",
  withCredentials: false,
  timeout: 10000,
})

export const sponsorApi = { /** 광고주 api */

  phoneNum({ ...props }) {
    const {
      sponsorId,
      authenticationType,
      phone,
      claim,
    } = props

    const options = {
      url: `/sponsors/${sponsorId}/authentications/${authenticationType}`,
      data: { phone, claim },
      method: 'POST'
    }

    return {
      async create() {

        const response = await axios(options);

        if (response?.data?.success) {

          return response

        } else {

          throw new Error(response.data.error);
        }
      }
    }
  },

 businessAuth(businessNum, businessName, businessSince){
  
  return {
    async promise(){
      try{

        const options = {
          url: `/sponsors/${businessNum}/authentications/VERIFY_BUSINESS`,
          method: 'POST',
          data: {
            business_number: businessNum,
            ceo_name: businessName,
            start_date: businessSince
          }
        }
        
        const response = await axios(options);

        if(response){

          return response.data.response
        }

      }catch(error){

        throw error.response.status
      }
    }
  }
 }
}

export const creatorApi = { /** 크리에이터 api */

  checkCreator(creatorId){  // 캠페인 서버 크리에이터 아이디 존재여부 확인
    
    return {
      async promise(){
        try{
          const options = {
            url: `/creators/${creatorId}?part=none`,
            method: 'GET',
          }

          const response = await axios(options);

          if(response){

            return response
          }

        }catch(error){
          
          throw error.response.status
        }
      }
    }
  },

  checkCreatorContract(creatorId){

    return {
      async promise(){
        try{
          const options = {
            url: `/contracts/?scope=public&creator_id=${creatorId}`,
            method: 'GET',
          }

          const response = await axios(options);

          if(response){

            return response
          }

        }catch(error){
          
          throw error.response.status
        }
      }
    }
  },

  activateCampaignService(youtubeCode, instagramCode, avatarId, token, email, phone){ // 캠페인 서비스 활성화

    return{
      async promise(){
        try{
          const option = {
            url: `/creators/${avatarId}`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              "challenge": uuidv4(),
              "timestamp": {
                "unix": unixTime,
                "human": humanTime,
              },
              "password": 'password02@~',
              "name": avatarId,
              "sns_information": {
                "youtube": {
                  "auth": {
                    "code": youtubeCode ? encodeURIComponent(youtubeCode) : ''
                  }
                },
                "instagram": {
                  "auth": {
                    "code": instagramCode ? encodeURIComponent(instagramCode) : ''
                  }
                }
              },
              "setting_information": {
                "favorite_campaigns": ['화보'],
                "open_schedule": {
                  "instagram": {
                    "type": "week",
                    "opens": [1, 2, 3, 4, 5, 6]
                  },
                  "youtube": {
                    "type": "week",
                    "opens": [1, 2, 3, 4, 5, 6]
                  },
                  "tiktok": {
                    "type": "week",
                    "opens": [1, 2, 3, 4, 5, 6]
                  }
                },
                "self_value": {
                  "online": {
                    "ppl": OFFER_PRICE.MIN,
                    "standard_ppl": OFFER_PRICE.MIN,
                    "branded": OFFER_PRICE.MAX,
                    "limits": [],
                    "ranges": [OFFER_PRICE.MIN, OFFER_PRICE.MAX],
                    "show": 'private'
                  },
                  "offline": {
                    "ppl": OFFER_PRICE.MIN,
                    "standard_ppl": OFFER_PRICE.MIN,
                    "branded": OFFER_PRICE.MAX,
                    "limits": [],
                    "ranges": [OFFER_PRICE.MIN, OFFER_PRICE.MAX],
                    "show": 'private'
                  }
                },
                "contact": {
                  "whether_subscribe_biztalk": true
                }
              },
              "owner_information": {
                "contact": {
                  "email": email,
                  "phone": phone
                },
                "business": {
                  "type": '',
                  "number": '',
                  "name": '',
                  "since": ''
                },
                "identification": {
                  "type": "temp",
                  "name": "임시명",
                  "number": '9903011123456'
                },
              },
              "bio_information": {
                "birth": '19990301',
                "gender": '1'
              },
              "page_information": {
                "pageColor": 'blue'
              }
            }
          }
      
          const response = await axios(option);

          if (response) {

            return response
          }

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  joinCreator() {  // 캠페인 서비스 회원가입 레거시

    /* 마케팅 동의 */
    const whetherSubscribeBiztalk = true;  // 카카오 동의가 불필요하다고 전달 받음

    /* 사업자 정보 */
    const businessType = (window.sessionStorage.getItem('businessType') === '없음' ? '' : (window.sessionStorage.getItem('businessType') === '개인 사업자') ? 'sole' : 'corporate');
    const businessNum = window.sessionStorage.getItem('businessType') !== '없음' ? window.sessionStorage.getItem('businessNum') : '';
    const businessName = window.sessionStorage.getItem('businessType') !== '없음' ? window.sessionStorage.getItem('businessName') : '';
    const businessSince = window.sessionStorage.getItem('businessType') !== '없음' ? window.sessionStorage.getItem('businessSince') : '';

    /* 기본정보 */
    const creatorId = window.sessionStorage.getItem('id');  // 아이디
    const password = window.sessionStorage.getItem('password') ?? 'password02~'  // 아이디
    const nickName = window.sessionStorage.getItem('nickName'); // 닉네임
    const gender = window.sessionStorage.getItem('gender') === '남성' ? '1' : '2' // 성별
    const birthDate = window.sessionStorage.getItem('birth'); // 생년월일

    /* 본인인증 */
    const phone = window.sessionStorage.getItem('phone');
    const email = window.sessionStorage.getItem('email'); // 이메일
    const token = window.sessionStorage.getItem('token');

    /* 개인설정 */
    const concept = window.sessionStorage.getItem('concept')?.split(','); // 선호하는 캠페인
    const pageColor = window.sessionStorage.getItem('pageColor') ?? 'blue'  // 페이지 컬러
    const ppl = window.sessionStorage.getItem('price') === '설정함' ? window.sessionStorage.getItem('min') : ''; // 최소 단가
    const branded = window.sessionStorage.getItem('price') === '설정함' ? window.sessionStorage.getItem('max') : ''; // 최대 단가
    const range = window.sessionStorage.getItem('price') === '설정함' ? [window.sessionStorage.getItem('min'), window.sessionStorage.getItem('max')] : []; // 범위

    /* 계정연동 */
    const youtubeCode = window.sessionStorage.getItem('youtube_code') ?? '';  // 유튜브 코드
    // const instagramCode = window.sessionStorage.getItem('instagram_code') ?? '';  // 인스타 코드

    /* 생체정보 */
    const realName = window.sessionStorage.getItem('realName') ?? ''

    const option = {
      url: `/creators/${creatorId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        "challenge": uuidv4(),
        "timestamp": {
          "unix": unixTime,
          "human": humanTime,
        },
        "password": password,
        "name": nickName,
        "sns_information": {
          "youtube": {
            "auth": {
              "code": youtubeCode
            }
          },
          "instagram": {
            "auth": {
              "code": ''
            }
          }
        },
        "setting_information": {
          "favorite_campaigns": concept,
          "open_schedule": {
            "instagram": {
              "type": "week",
              "opens": [1, 2, 3, 4, 5, 6]
            },
            "youtube": {
              "type": "week",
              "opens": [1, 2, 3, 4, 5, 6]
            },
            "tiktok": {
              "type": "week",
              "opens": [1, 2, 3, 4, 5, 6]
            }
          },
          "self_value": {
            "online": {
              "ppl": ppl,
              "standard_ppl": ppl,
              "branded": branded,
              "limits": [],
              "range": range
            },
            "offline": {
              "ppl": ppl,
              "standard_ppl": ppl,
              "branded": branded,
              "limits": [],
              "range": range
            }
          },
          "contact": {
            "whether_subscribe_biztalk": whetherSubscribeBiztalk
          }
        },
        "owner_information": {
          "contact": {
            "email": email,
            "phone": phone
          },
          "business": {
            "type": businessType,
            "number": businessNum,
            "name": businessName,
            "since": businessSince
          },
          "identification": {
            "type": "temp",
            "name": realName,
            "number": '9903011234567'
          },
        },
        "bio_information": {
          "birth": birthDate,
          "gender": gender
        },
        "page_information": {
          "pageColor": pageColor
        }
      }
    }

    return {
      async promise() {

        try {
          const response = await axios(option);

          if (response.data.success) {

            return response
          }

        } catch (error) {

          throw error.response.status
        }
      }
    }
  },

  bankAuth(creatorId, token, authentication_type, body) { /** 크리에이터 계좌점유 인증 api */
    let options;

    if (authentication_type === 'BANK_ACCOUNT_OTP_CREATE') {
      options = {
        url: `/creators/${creatorId}/authentications/${authentication_type}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          challenge: uuidv4(),
          ...body
        },
      }

    } else if (authentication_type === 'BANK_ACCOUNT_OTP_CLAIM') {
      options = {
        url: `/creators/${creatorId}/authentications/${authentication_type}`,
        method: 'POST',
        data: {
          challenge: uuidv4(),
          ...body
        },
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
    }

    return {
      async promise() {

        try {
          const response = await axios(options);

          if (response.data.success) {

            return response
          }

        } catch (error) {

          throw error.response.status
        }
      }
    }
  },

  patchUserInfo(creatorId, token, body) { /** 크리에이터 정보 갱신 api */

    const options = {
      url: `/creators/${creatorId}`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        challenge: uuidv4(),
        timestamp: {
          "unix": unixTime,
          "human": humanTime,
        },
        ...body,
      }
    }

    return {
      async promise() {

        try {
          const response = await axios(options);

          if (response.data.success) {

            return response
          }

        } catch (error) {

          throw error.response.status
        }
      }
    }
  },

  phoneNum({ ...props }) {  /** 크리에이터 전화번호 인증 api */
    const {
      sponsorId,
      authenticationType,
      phone,
      claim,
    } = props

    const options = {
      url: `/sponsors/${sponsorId}/authentications/${authenticationType}`,
      data: { phone, claim },
      method: 'POST'
    }

    return {
      async create() {

        const response = await axios(options);

        if (response?.data?.success) {

          return response

        } else {

          throw new Error(response.data.error);
        }
      }
    }
  },

  checkEmail(creatorId, address){

    return{
      async requestCode(){
        const options = {
          url: `/creators/${creatorId}/authentications/EMAIL_CHECK_OPEN`,
          method: 'POST',
          data: {
            address: address
          }
        }
        try{

          const response = await axios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      },
      async requestAuth(code){
        const options = {
          url: `/creators/${creatorId}/authentications/EMAIL_CHECK_CLOSE`,
          method: 'POST',
          data: {
            address: address,
            claim: code
          }
        }

        try{

          const response = await axios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      },
    }
  },

  checkPhone(creatorId, phone){

    return{
      async requestCode(){
        const options = {
          url: `/creators/${creatorId}/authentications/PHONE_CHECK_OPEN`,
          method: 'POST',
          data: {
            phone: phone
          }
        }
        try{

          const response = await axios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      },
      async requestAuth(code){
        const options = {
          url: `/creators/${creatorId}/authentications/PHONE_CHECK_CLOSE`,
          method: 'POST',
          data: {
            phone: phone,
            claim: code
          }
        }

        try{

          const response = await axios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      },
    }
  },

  contactSponsor(props) { /** 캠페인 제안 수락/거절 api */  
    const {
      purpose,
      name,
      beneficiary_id,
      beneficiary_name,
      schedule,
      contract_id,
      token,
    } = props;

    return {
      async promise(){
        try{
          let formData = new FormData();
  
          formData.append('challenge', uuidv4());
          formData.append('purpose', purpose);
          formData.append('timestamp[unix]', unixTime);
          formData.append('timestamp[human]', humanTime);
          formData.append('name', name);
          formData.append('beneficiary_id', beneficiary_id);
          formData.append('beneficiary_name', beneficiary_name);
          formData.append('schedule', schedule);
      
          let headers = {
            headers: {
              "Authorization": `${token}`,
              "Content-type": 'multipart/form-data'
            },
          }
      
          const response = await axios.patch(`/contracts/${contract_id}`, formData, headers);
      
          if (response) {
      
            return response
          }
        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  changeCampaignProgress(campaignData, token){
    let formData = new FormData();

    formData.append('challenge', uuidv4());
    formData.append('timestamp[unix]', unixTime);
    formData.append('timestamp[human]', humanTime);
    formData.append('name', campaignData?.content?.campaign?.brand);

    const avatarId = campaignData?.participant?.beneficiary?.id
    formData.append('beneficiary_id', avatarId);

    formData.append('beneficiary_name', campaignData?.participant?.beneficiary?.name);
    
    const sns = campaignData.content.schedule.social_network_services;
    const scheduleYear = campaignData?.content?.schedule?.[sns]?.year
    const scheduleHumanMonth = +campaignData?.content?.schedule?.[sns]?.month + 1
    const scheduleWeek = campaignData?.content?.schedule?.[sns]?.week
    const schedule = `${scheduleYear}년 ${scheduleHumanMonth}월 ${scheduleWeek}주`
    formData.append('schedule', schedule);
    
    const contractId = campaignData.id

    const headers = {
      headers: {
        "Authorization": `${token}`,
        "Content-type": 'multipart/form-data'
      },
    }

    return {
      async accept(){
        try{
          formData.append('purpose', 'acceptance');

          const response = await axios.patch(`/contracts/${contractId}?creator_id=${avatarId}`, formData, headers);

          return response

        }catch(error){

          throw error.response.status
        }
      },
      async refuse(){
        try{
          formData.append('purpose', 'refuse');

          const response = await axios.patch(`/contracts/${contractId}?creator_id=${avatarId}`, formData, headers);

          return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  info(creatorId, token) {  /** 크리에이터 정보 api */

    if (!creatorId) {

      throw new Error("creatorId is required");
    }

    return {
      async getProfile() {
        const options = {
          url: `/creators/${creatorId}?part=open`,
          method: 'GET',
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      },

      async getDetail() {
        const options = {
          url: `/creators/${creatorId}?part=setting`,
          method: 'GET',
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      },

      async getStatistics() {
        const options = {
          url: `/creators/${creatorId}?part=statistics`,
          method: 'GET',
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      },

      async getContractList() {
        const options = {
          url: `/creators/${creatorId}?part=rent`,
          method: 'GET',
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      },

      async getPageInfo() {
        const options = {
          url: `/creators/${creatorId}?part=page`,
          method: 'GET',
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      },

      async getOwner(){
        const options = {
          url: `/creators/${creatorId}?part=owner`,
          method: 'GET',
          headers: {
            Authorization: `${token}`
          },
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      },

      async getSns(){
        const options = {
          url: `/creators/${creatorId}?part=sns`,
          method: 'GET',
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      },
    };
  },

  login(id, password){  /** 크리에이터 로그인 api */
    const options = {
      url: `/creators/${id}/authentications/LOGIN`,
      method: 'POST',
      data: {
        password
      }
    }

    return {

      async promise(){

        try{

          const response = await axios(options);

          return response

        }catch(error){

          throw error.response.status;
        }
      }
    }
  },

  kakaoLogin(code) {  /** 크리에이터 로그인 api */
    const options = {
      url: `/user/kakaotalk_login`,
      method: 'POST',
      data: {
        challenge: uuidv4(),
        timestamp: {
          unix: unixTime,
          human: humanTime,
        },
        oauth_claim: {
          kakaotalk: code
        },
      }
    }

  return {

    async promise(){

      try{

        const response = await authAxios(options);

        return response

      }catch(error){

        throw error.response.status;
      }
    }
  }
},

  find(creatorId, email, type) {  /** 크리에이터 아이디/비밀번호 찾기 api */

    return {

      async promise(){
        const options = {
          url: `/creators/${creatorId}/authentications/${type}`,
          method: 'POST',
          data: {
            address: email
          }
        }

        try{

          const response = await axios(options);
          
          if(response){

            return response
          }

        }catch(error){

          throw error.response.status
        }
      },

      async open(){
        const options = {
          url: `/creators/=/authentications/EMAIL_EQUATION_OPEN`,
          method: 'POST',
          data: {
            address: email,
            name: '임시명'
          }
        }

        try{

          const response = await axios(options);
          
          if(response){

            return response
          }

        }catch(error){

          throw error.response.status
        }
      },

      async close(code){
        const options = {
          url: `/creators/=/authentications/EMAIL_EQUATION_CLOSE`,
          method: 'POST',
          data: {
            address: email,
            name: '임시명',
            claim: code
          }
        }

        try{

          const response = await axios(options);
          
          if(response){

            return response
          }

        }catch(error){

          throw error.response.status
        }
      },
    }
  },

  deleteCreator(creatorId, token, body){ /** 크리에이터 회원탈퇴 api */
    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      url: `/creators/${creatorId}`,
      method: 'DELETE',
      data: body
    }

    return{
      async promise(){

        try{

          const response = await axios(options);

          if(response){

            return response
          }
          
        }catch(error){

          throw error.response.error
        }
      }
    }
  },

  compromise(creatorId, contractId, token, pastCampaignData) { /** 크리에이터 협의하기 api */

    return {
      async promise(data) {
        const formData = new FormData()
        const keys = Object.keys(data);

        keys.forEach((key) => { // 예외 필드 제외하고 formData 할당
          const exceptions = [
            FormKey.media,
            FormKey.dates,
            FormKey.attachments,
            FormKey.calendarType,
            FormKey.instagramPhotoCount,
            FormKey.allotedTime,
            FormKey.usePlan,
            FormKey.useChannel,
            'useInstagram',
            'useFacebook',
            'useYoutube',
            'useEtc',
            FormKey.needRunningTime,
            FormKey.runningMin,
            FormKey.runningSec,
            FormKey.runningTime,
            FormKey.campaignConceptName,
            FormKey.exposeList,
            FormKey.apealPoint,
            FormKey.apealMethod,
            FormKey.angle,
            FormKey.precaution,
            FormKey.description,
          ];

          if (!(exceptions.includes(key)) && data[key]) {

            formData.append(key.replaceAll("-", "[").replaceAll("/", "]"), data[key]);
          }
        });

        try{  // 상세 내용 관련, 이전에 광고주가 입력한 필드는 무조건 보냄

          if(data[FormKey.campaignConceptName]){  // 캠페인 컨셉
            
            formData.append(FormKey.campaignConceptName.replaceAll("-", "[").replaceAll("/", "]"), data[FormKey.campaignConceptName]);
          }

          if(data[FormKey.exposeList]){  // 노출 제품 리스트
            
            formData.append('campaign[item][0][name]', data[FormKey.exposeList] ?? '')
          }

          if(data[FormKey.apealPoint]){ // 어필 포인트
            
            formData.append('campaign[item][0][point]', data[FormKey.apealPoint] ?? '')
          }

          if(data[FormKey.apealMethod]){  // 어필 방식
            
            formData.append('campaign[item][0][how]', data[FormKey.apealMethod] ?? '')
          }

          if(data[FormKey.angle]){  // 희망 앵글
            
            formData.append('campaign[item][0][angle]', data[FormKey.angle] ?? '')
          }

          if(data[FormKey.precaution]){ // 주의사항
            
            formData.append(FormKey.precaution, data[FormKey.precaution] ?? '')
          }

          if(data[FormKey.description]){  // 그 외 남길말
            
            formData.append(FormKey.description, data[FormKey.description] ?? '')
          }

        }catch(error){
          
          throw '추가 기입 사항 오류'
        }
        try {  // 유튜브 (업로드 케이스)는 유지기간 3개월로 고정

          if (!data[FormKey.lifetime] && data[FormKey.media] === 'youtube' && data[FormKey.category] !== '행사/강연') {

            formData.append(FormKey.lifetime.replaceAll("-", "[").replaceAll("/", "]"), '3');
          }

        } catch (error) {

          throw '유지기간을 다시 확인해 주세요'
        }

        if (data[FormKey.format] === '스토리') {  // 스토리는 캠페인 유지 기간 max로 고정

          formData.set(FormKey.lifetime.replaceAll("-", "[").replaceAll("/", "]"), '99999');
        }

        /* 컨텐츠 최소 분량 */
        if (data[FormKey.category] !== '행사/강연' && data[FormKey.format] !== '피드' && data[FormKey.format] !== '스토리') {  // 캠페인 최소 분량 필요없는 캠페인은 제외

          if (data[FormKey.needRunningTime] === '유관' && data[FormKey.runningTime]) {  // 캠페인 최소 분량 유관

            if (data[FormKey.format] === 'Shorts' || data[FormKey.format] === '릴스') { // 쇼츠 릴스 케이스 handling

              if (data[FormKey.format] === 'Shorts' && data[FormKey.runningSec] == '60') {

                formData.append(FormKey.runningTime.replaceAll("-", "[").replaceAll("/", "]"), '99999')

              } else if (data[FormKey.format] === '릴스' && data[FormKey.runningSec] == '90') {

                formData.append(FormKey.runningTime.replaceAll("-", "[").replaceAll("/", "]"), '99999')

              } else {

                formData.append(FormKey.runningTime.replaceAll("-", "[").replaceAll("/", "]"), data[FormKey.runningSec]);
              }

            } else { // 쇼츠 릴스 케이스 외
              let minToSec = Number(data[FormKey.runningMin]) * 60;
              let secToSec = Number(data[FormKey.runningSec]);
              let totalSec = minToSec + secToSec;

              formData.append(FormKey.runningTime.replaceAll("-", "[").replaceAll("/", "]"), `${totalSec}`);
            }

          } else {  // 캠페인 최소 분량 무관

            formData.append(FormKey.runningTime.replaceAll("-", "[").replaceAll("/", "]"), '0');
          }
        }

        /* 2차 활용 관련 (공통) */
        try {

          if (data[FormKey.usePlan] !== '없음' && data[FormKey.useChannel].length > 0) {
              const pastUseChannels = typeof pastCampaignData.content.campaign.derived_plan.channels === 'string' ? [pastCampaignData.content.campaign.derived_plan.channels] : [...pastCampaignData.content.campaign.derived_plan.channels]

              formData.append(FormKey.usePlan.replaceAll("-", "[").replaceAll("/", "]"), data[FormKey.usePlan]);  // 2차 활용 계획
              
              data[FormKey.useChannel]?.forEach(function (item) { // 2차 활용 채널
                
                if (item) {
                  
                  formData.append(FormKey.useChannel.replaceAll("-", "[").replaceAll("/", "]"), pastUseChannels.find(channel => channel.includes(item)))
                }
              })
          
          }else if (pastCampaignData.content.campaign?.derived_plan?.period && data[FormKey.usePlan] === '없음'){ // 2차 활용 계획 없음으로 변경

            formData.append('campaign[derived_plan]', '');
          }

        } catch (error) {

          throw '2차 활용 계획을 다시 입력해 주세요'
        }

        /* 업로드 장수 관련 */
        try {

          if (data[FormKey.format] === '피드' || data[FormKey.format] === '스토리') {

            formData.append(FormKey.instagramPhotoCount.replaceAll("-", "[").replaceAll("/", "]"), data[FormKey.instagramPhotoCount]);
          }

        } catch (error) {

          throw '업로드 장수를 다시 입력해 주세요'
        }

        /* 첨부파일 핸들링*/

        if (data[FormKey.attachments]) {

          const attachments = Array.from < File > (data[FormKey.attachments]);

          try {

            if (attachments?.length > 0) {

              attachments.forEach((file) => formData.append("attachments", file));
            }

          } catch (error) {

            throw '참고 자료를 다시 확인해 주세요'
          }
        }

        /* 캠페인 일정 핸들링 */
        const calendarType = data[FormKey.calendarType] === "week_day" ? "day" : "week";  // 현재는 week으로 고정
        const media = data[FormKey.media];
        const dates = Array(data[FormKey.dates]) // ex) 20220504 / year(4)month(2)period(2)
        const firstDate = new Date(Number(dates[0].substring(0, 4)), Number(dates[0].substring(4, 6)) - 1); // Number(dates[0].substring(4, 6))은 선택한 월 + 1 값임
        const year = firstDate.getFullYear(); // 선택한 연도
        const month = firstDate.getMonth(); // 선택한 월 - 1
        const startDay = firstDate.getDay();  // 선택한 월 시작 요일
        const weeksOrDays = dates.map((item) => Number(item.slice(-2)));  // 선택한 주차 원본 그대로

        const dateList = [
          ...new Array(startDay).fill(undefined),
          ...new Array(new Date(year, month + 1, 0).getDate()).fill(0).map((item, index) => index + 1)
        ];

        const startDateOfWeek = (calendarType === "week" ? weeksOrDays[0] === 1 ? dateList[startDay] : dateList[(weeksOrDays[0] - 1) * 7] : weeksOrDays[0]) // 해당 주차의 첫번쨰 일자

        if (data[FormKey.category] === '행사/강연' && data[FormKey.allotedTime]) { // 행사강연 특정 일정 선택

          if (typeof data[FormKey.allotedTime] === 'string') {
            const allotedTimeData = `${new Date(year, month, Number(data[FormKey.allotedTime]))},${new Date(year, month, Number(data[FormKey.allotedTime]))}`

            formData.append(FormKey.allotedTime.replaceAll('-', '[').replaceAll('/', ']'), allotedTimeData);

          } else {

            data[FormKey.allotedTime].forEach(function (item) {

              formData.append(FormKey.allotedTime.replaceAll("-", "[").replaceAll("/", "]"), `${new Date(year, month, Number(item))},${new Date(year, month, Number(item))}`)
            })
          }
        }

        formData.append(`schedule[${media}][year]`, `${year}`);
        formData.append(`schedule[${media}][month]`, `${month}`);
        formData.append(`schedule[${media}][type]`, calendarType);

        weeksOrDays.forEach((weekOrDay) => {

          formData.append(`schedule[${media}][periods]`, `${weekOrDay}`); // 선택된 주차 원본 그대로 보내는걸로 변경

          const timestamp = new Date();
          const currentDate = new Date(); // 오늘
          currentDate.setDate(currentDate.getDate() + 1); // 제안은 오늘 바로 다음날 가능하기 때문에 currentDate = 내일
          const date = new Date(year, month, startDateOfWeek);  // 선택된 주차에 해당하는 첫번째 날

          // 오늘의 내일과 선택된 주차에 해당하는 첫번째 날 비료
          if (currentDate.getTime() > date.getTime()) { // 내일 날짜가 선택한 주차 첫번째 날보다 미래일 때
            let scheduleDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

            formData.append(`schedule[${media}][unixes]`, `${scheduleDate.getTime()}`);
            formData.append(`schedule[${media}][humans]`, `${scheduleDate.toString()}`);
            formData.append("timestamp[unix]", `${timestamp.getTime()}`);
            formData.append("timestamp[human]", `${timestamp.toString()}`);

          } else {

            formData.append(`schedule[${media}][unixes]`, `${date.getTime()}`);
            formData.append(`schedule[${media}][humans]`, `${date.toString()}`);
            formData.append("timestamp[unix]", `${timestamp.getTime()}`);
            formData.append("timestamp[human]", `${timestamp.toString()}`);
          }
        });

        /* 과거 캠페인 내용과 무조건 같아야하는 필드 */
        formData.set(FormKey.brand.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.content?.campaign?.brand) // 브랜드
        formData.set(FormKey.campaignName.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.content?.campaign?.name) // 브랜드
        formData.set(FormKey.sponsorName.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.name) // 광고주 이름
        formData.set(FormKey.ceoName.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.ceo_name ?? '');  // 대표자명
        formData.set(FormKey.sponsorBrand.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.brand ?? ''); // sponsorBrand
        formData.set(FormKey.businessNum.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.business_number ?? ''); // 사업자번호
        formData.set(FormKey.startDate.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.start_date ?? '');  // 개업연월일
        formData.set(FormKey.businessType.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.business_type ?? '');  // 사업자유형
        formData.set(FormKey.businessTaxType.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.business_tax_type ?? ''); // 세금과세유형
        formData.set(FormKey.sponsorEmail.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.email ?? ''); // 세금과세유형
        formData.set(FormKey.sponsorPhoneNumber.replaceAll("-", "[").replaceAll("/", "]"), pastCampaignData?.participant?.sponsor?.phone ?? ''); // 세금과세유형
        formData.append('participant[sponsor][id]', pastCampaignData?.participant?.sponsor?.business_number);  // 광고주 회원가입 기능 추가 후에 광고주 아이디 삽입
        
        /* 공통 필드 formData에 할당 */
        formData.set("social_network_services", media);
        formData.set('challenge', uuidv4());
        formData.set('purpose', 'compromise');
        formData.set('timestamp[unix]', unixTime);
        formData.set('timestamp[human]', humanTime);

        const options = {
          url: `/contracts/${contractId}?creator_id=${creatorId}`,
          method: 'PATCH',
          headers: {
            Authorization: token,
          },
          data: formData
        }


        try {

          const response = await axios(options);

          if (response) {

            return response
          }

        } catch (error) {

          throw error.response.status
        }
      }
    }
  },

  checkAuth(creatorId, token){  // 토큰 활성화 확인용 API
    return {
      async promise(){
        const options = {
          url: `/creators/${creatorId}?part=owner`,
          method: 'GET',
          headers: {
            Authorization: `${token}`
          },
        }

        try{
  
          const response = await axios(options);
  
          if (response) {
  
            return response.data?.response;
          }

        }catch(error){

          throw error.response.status;
        }
      }
    }
  },

  getSingleCampaignTr0(campaignId, token){
    
    return{
      async promise(){
        const options = {
          url: `/tr0/${campaignId}?structure=meta`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        try{

          const response = await axios(options);

          return response

        }catch(error){

          throw error.response.status;
        }
      }
    }
  },

  getMeercatUserData(avatarId, token) {

    return {
      async promise() {
        try {
          const options = {
            url: `/api/v1/report-users/?avatar_id=${avatarId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            },
          }

          const response = await meercatAxios(options);

          if (response) {

            return response
          }

        } catch (error) {

          throw error.response.status
        }
      }
    }
  },

  getTempPassword(id, authToken){ // 임시비밀번호 요청
    const options = {
      url: `/users/${id}/authentications/LOGIN`,
      method: 'POST',
      headers: {
        Authorization: `${authToken}`
      },
      data: {
        challenge: uuidv4(),
        timestamp: {
          unix: unixTime,
          human: humanTime,
        },
      }
    }

    return {

      async promise(){

        try{

          const response = await authAxios(options);

          return response

        }catch(error){

          throw error.response.status;
        }
      }
    }
  },

  checkBusiness(businessNum, businessName, businessSince){

    return {
      async promise(){
        try{
          const options = {
            url: `/creators/creatorDummy/authentications/VERIFY_BUSINESS`,
            method: 'POST',
            data: {
              business_number: businessNum,
              ceo_name: businessName,
              start_date: businessSince
            },
          }

          const response = await axios(options)

          if(response) return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  getContractFile(legalDocumentId, token){

    return {
      async promise(){
        try{
          const options = {
            url: `/modusign_document_files?legal_document_id=${legalDocumentId}`,
            method: 'GET',
            headers: {
              Authorization: `${token}`
            },
          }

          const response = await axios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  }
}

export const authApi = {
  kakaoSubmit(code){
    return (async () => {
      try{
        const options = {
          url: `/users/kakao/join?code=${code}`,
          method: 'GET'
        }

        const response = await authAxios(options)

        return response
      }catch(error){

        throw error.response.status
      }
    })()
  },

  authLogin(email, password){  /** 크리에이터 로그인 api */
    const options = {
      url: `/v2/auth/signin?authentication_type=LOGIN`,
      method: 'POST',
      data: {
        challenge: uuidv4(),
        timestamp: {
          unix: unixTime,
          human: humanTime,
        },
        email: email,
        password: password
      }
    }

    return {

      async promise(){

        try{

          const response = await authAxios(options);

          return response

        }catch(error){

          throw error.response.status;
        }
      }
    }
  },

  checkIntegrateId(integrateId){  // 통합회원 아이디 존재여부

    return{
      async promise(){        
        try{
          const options = {
            url: `/users/${integrateId}?part=none`,
            method: 'GET',
          }

          const response = await authAxios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  findUserId(phone, email){

    return {

      async open(){
        const options = {
          url: '/user/equation',
          method: 'POST',
          data: {
            challenge: uuidv4(),
            timestamp: {
              unix: unixTime,
              human: humanTime,
            },
            phone,
            email,
            step: 'open',
            name: '엄준호',
            '?': 'email'
          }
        }

        try{

          const response = await authAxios(options)

          return response
        }catch(error){

          throw error.response.status
        }

      },

      async close(code){
        const options = {
          url: '/user/equation',
          method: 'POST',
          data: {
            challenge: uuidv4(),
            timestamp: {
              unix: unixTime,
              human: humanTime,
            },
            phone,
            email,
            step: 'close',
            name: '엄준호',
            claim: code,
            '?': 'email'
          }
        }

        try{

          const response = await authAxios(options)

          return response
        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  findUserPassword(email){

    return {

      async open(){
        const options = {
          url: '/v2/auth/send-reset-password-code',
          method: 'POST',
          data: {
            challenge: uuidv4(),
            timestamp: {
              unix: unixTime,
              human: humanTime,
            },
            email
          }
        }

        try{

          const response = await authAxios(options)

          return response
        }catch(error){

          throw error.response.status
        }

      },

      async close(code){
        const options = {
          url: '/v2/auth/check-reset-password-code',
          method: 'POST',
          data: {
            challenge: uuidv4(),
            timestamp: {
              unix: unixTime,
              human: humanTime,
            },
            email,
            code
          }
        }

        try{

          const response = await authAxios(options)

          return response
        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  checkAvatarId(avatarId){  // 아바타 아이디 중복 확인

    return {
      async promise(){
        try{
          const options = {
            url: `/avatars/${avatarId}?part=none`,
            method: 'GET',
          }

          const response = await authAxios(options);

          if(response){

            return response
          }

        }catch(error){
          
          throw error.response.status
        }
      }
    }
  },

  joinUser(userId, password, kakaoCode) { // 통합회원 생성 (회원가입)
    const options = {
      url: `/users/${userId}`,
      method: 'POST',
      data: {
        challenge: uuidv4(),
        timestamp: {
          unix: unixTime,
          human: humanTime,
        },
        password: password,
        oauth_claim: {
          kakaotalk: kakaoCode ?? '1'
        },
      }
    }

    return {
      async promise() {

        try {

          const response = await authAxios(options)

          return response

        } catch (error) {

          throw error.response.status
        }
      }
    }
  },

  registerAvatar(token, link, old_token){  // 아바타 수동 생성

    return{
      async promise(){
        try{
          const avatarId = link
          const options = {
            headers: {
              Authorization: `Bearer ${token}`
            },
            url: `/avatars/${avatarId}`,
            method: 'POST',
            data: {
              challenge: uuidv4(),
              timestamp: {
                unix: unixTime,
                human: humanTime
              },
              old_token
            }
          }

          const response = await authAxios(options);

          return response

        }catch(error){
          
          throw error.response.status
        }
      }
    }
  },

  registerLink(avatarId, token, link) { // 링크트리 활성화
    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      url: `/links/${link}`,
      method: 'POST',
      data: {
        challenge: uuidv4(),
        timestamp: {
          unix: unixTime,
          human: humanTime,
        },
        avatar_id: avatarId,
        home: link
      }
    }

    return {
      async promise() {

        try {

          const response = await authAxios(options)

          return response

        } catch (error) {

          throw error.response.status
        }
      }
    }
  },

  getIntegrateUserData(integrateId, token){  // 통합회원 데이터 확인

    return{
      async promise(){
        try{
          const options = {
            url: `/users/${integrateId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            },
          }

          const response = await authAxios(options)

          return response

        }catch(error){
          
          throw error.response.status
        }
      }
    }
  },

  getAvatarData(token){ // 통합회원 로그인 토큰에 해당하는 아바타 데이터 확인
    
    return{
      async promise(){
        const options = {
          method: 'GET',
          url: '/avatars/',
          headers: {
            Authorization: `Bearer ${token}`
          },
        }

        try{
          const response = await authAxios(options)

          return response
          
        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  registerYoutube(avatarId, token, youtubeCode, destination){ // 통합서버 유튜브 등록

    return {
      async promise(){
        try{

          const options = {
            headers: {
              Authorization: `Bearer ${token}`
            },
            url: `youtuber_delegations/${encodeURIComponent(youtubeCode)}`,
            method: 'POST',
            data: {
              avatar_id: avatarId,
              challenge: uuidv4(),
              timestamp: {
                unix: unixTime,
                human: humanTime
              },
              destination
            }
          }

          const response = await authAxios(options)

          if(response) return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  registerInstagram(avatarId, token, instagramCode, destination){  // 통합서버 인스타그램 등록

    return {
      async promise(){
        try{

          const options = {
            headers: {
              Authorization: `Bearer ${token}`
            },
            url: `instagrammer_delegations/${encodeURIComponent(instagramCode)}`,
            method: 'POST',
            data: {
              avatar_id: avatarId,
              challenge: uuidv4(),
              timestamp: {
                unix: unixTime,
                human: humanTime
              },
              destination
            }
          }

          const response = await authAxios(options)

          if(response) return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  checkDelegations(avatarId, token){
    return {
      async youtube(){
        try{
          const options = {
            url: `/youtuber_delegations/?avatar_id=${avatarId}`,
            headers: {
              Authorization: `Bearer ${token}`
            },
            method: 'GET'
          }

          const response = await authAxios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      },

      async instagram(){
        try{
          const options = {
            url: `/instagrammer_delegations/?avatar_id=${avatarId}`,
            headers: {
              Authorization: `Bearer ${token}`
            },
            method: 'GET'
          }

          const response = await authAxios(options)

          return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  getAvatarResource(avatarId, token){

    return {
      
      async youtube(){
        try{
          const options = {
            url: `/youtuber_channels?avatar_id=${avatarId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            },
          }

          const response = await authAxios(options)

          return response

        }catch(error){
        
          throw error.response.status
        }
      },

      async instagram(){
        try{
          const options = {
            url: `/instagrammer_accounts?avatar_id=${avatarId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            },
          }

          const response = await authAxios(options)

          return response

        }catch(error){
          
          throw error.response.status
        }
      },

      async profilePageVisit(){
        try{
          const options = {
            url: `/hits?avatar_id=${avatarId}`,
            method: 'POST',
          }

          const response = await authAxios(options)

          return response

        }catch(error){
          
          throw error.response.status
        }
      },

      async getProfilePageView(){
        try{
          const options = {
            url: `/hits?avatar_id=${avatarId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            },
          }

          const response = await authAxios(options)

          return response

        }catch(error){
          
          throw error.response.status
        }
      }
    }
  },

  markCampaignActivation(avatarId, token){  // 캠페인 서비스 활성화 마킹

    return {
      async promise(){
        try{
          const options = {
            url: `avatars/${avatarId}`,
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              challenge: uuidv4(),
              timestamp: {
                unix: unixTime,
                human: humanTime,
              },
              services: ["CAMPAIGN"]
            }
          }

          const response = await authAxios(options);

          return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  patchUser(userId, token, body){

    return {
      async promise(){
        try{
          const options = {
            url: `/users/${userId}`,
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              challenge: uuidv4(),
              timestamp: {
                unix: unixTime,
                human: humanTime,
              },
              ...body
            }
          }

          const response = await authAxios(options);

          return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  deleteUser(userId, token, title, reason){

    return {
      async promise(){
        try{
          const options = {
            url: `/users/${userId}`,
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              challenge: uuidv4(),
              timestamp: {
                unix: unixTime,
                human: humanTime,
              },
              title: title,
              reason: reason
            }
          }

          const response = await authAxios(options);

          return response

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  registerPrimaryPhone(userId, token, phone){

    return (async () => {
      try{
        const options = {
          url: `/users/${userId}/info/PHONE`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`
          },
          data: {
            phone_number: phone
          }
        }

        const response = await authAxios(options)

        return response
      }catch(error){
        
        throw error.response.status
      }
    })()
  },

  registerPrimaryEmail(userId, token, email){

    return (async () => {
      try{
        const options = {
          url: `/users/${userId}/info/EMAIL`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`
          },
          data: {
            email: email
          }
        }

        const response = await authAxios(options)

        return response
      }catch(error){
      
        throw error.response.status
      }
    })()
  },

  checkPhoneDuplicate(phone){
    return (async () => {
      try{
        const options = {
          url: `/users/check/phone?phone=${phone}`,
          method: 'GET',
        }

        const response = await authAxios(options)

        return response
      }catch(error){
        
        throw error.response.status
      }
    })()
  },
  
  checkEmailDuplicate(email){
    return (async () => {
      try{
        const options = {
          url: `/users/check/email?email=${email}`,
          method: 'GET',
        }

        const response = await authAxios(options)

        return response
      }catch(error){
        
        throw error.response.status
      }
    })()
  }
}

export const analysisApi = {
  setAnalysisAlarm(avatarId, token, type){

    return{
      async promise(){
        try{
          const options = {
            url: `/api/v1/report-users/alarm?avatar_id=${avatarId}`,
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              is_active: type
            }
          }

          const response = await meercatAxios(options);

          if(response){

            return response
          }

        }catch(error){

          throw error.response.status
        }
      }
    }
  },
  
  activateService(avatarId, token){

    return {
      async promise(){
        try{
          const options = {
            url: `/api/v1/report-users`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              "avatar_id": avatarId
            }
          }

          const response = await meercatAxios(options);

          if(response){

            return response
          }

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  activateYoutubeReport(avatarId, token, reportUserId){
    return {
      async promise(){
        try{
          const options = {
            url: `/api/v1/youtube/users`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              "avatar_id": avatarId,
              "report_user_id": reportUserId
            }
          }

          const response = await meercatAxios(options);

          if(response){

            return response
          }

        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  getYoutubeUserData(avatarId, token){
    return {
      async promise(){
        try{
          const options = {
            url: `/api/v1/report-users/youtube-user/?avatar_id=${avatarId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            }
          }

          const response = await meercatAxios(options);

          if(response){

            return response
          }

        }catch(error){

          throw error.response.data.error;
        }
      }
    }
  },

  getYoutubeReportData(token, youtubeUserId){
    return {
      async promise(){
        try{
          const options = {
            url: `/api/v1/youtube/reports/${youtubeUserId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            }
          }

          const response = await meercatAxios(options);

          if(response){

            return response
          }

        }catch(error){

          throw error.response.data.error;
        }
      }
    }
  }
}

export const commonApi = {

  sponsorEmailAuth(sponsorId) {

    return {
      async requestCode(email) {
        const response = await axios.post(
          `/sponsors/${sponsorId}/authentications/EMAIL_CHECK_OPEN`,
          qs.stringify({ address: email }),
          {
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }
        );
        if (response.data.success) {

          return response.data.response;
        }

        throw new Error(response.data.error);
      },
      async requestAuth(email, code) {
        const response = await axios.post(
          `/sponsors/${sponsorId}/authentications/EMAIL_CHECK_CLOSE`,
          qs.stringify({ address: email, claim: code }),
          {
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }
        );
        if (response.data.success) {

          return response.data.response;
        }

        throw new Error(response.data.error);
      },
    }
  },
  creatorEmailAuth() {

    return {
      async requestCode(email) {
        let option = {
          method: 'POST',
          url: `/creators/${email}/authentications/EMAIL_CHECK_OPEN`,
          data: {
              address: email
          }
        }

        const response = await axios(option);

        if (response.data.success) {

          return response.data.response;
        }

        throw new Error(response.data.error);
      },
      async requestAuth(email, code) {
        let option = {
          method: 'POST',
          url: `/creators/${email}/authentications/EMAIL_CHECK_CLOSE`,
          data: {
            address: email,
            claim: code
          }
        }

        const response = await axios(option)
        if (response.data.success) {
          
          return response.data.response;
        }

        throw new Error(response.data.error);
      },
    }
  },

  businessAuth(businessNum, businessName, businessSince, handleSuccess) {
    
    return {
      async promise() {
        try{

          if (businessNum && businessName && businessSince) {
            let option = {
              url: `/creators/creatorDummy/authentications/VERIFY_BUSINESS`,
              method: 'POST',
              data: {
                business_number: businessNum,
                ceo_name: businessName,
                start_date: businessSince
              },
            }
  
            const response = await axios(option);
  
            if (response.data.success) {
  
              handleSuccess();
              return;
            }
          }
        }catch(error){

          throw error.response.status
        }
      }
    }
  },

  getCampaign(avatarId, contractId, token){ // creator_id 적용 완료

    return {
      async promise(){
        try{
          let option = {
            url: `/contracts/${contractId}?creator_id=${avatarId}`,
            method: 'GET',
            headers: {
              Authorization: `${token}`
            },
          }

          const response = await axios(option);

          return response;

        }catch(error){

          throw error.response.status
        }
      }
    }
  }
}

export const checkContract = async function (creatorId, token, contractId) { //캠페인 정보 확인 api, creator_id 적용 완료

  if (contractId && token) {

    try {
      let option = {
        url: `/contracts/${contractId}?creator_id=${creatorId}`,
        method: 'GET',
        headers: {
          Authorization: `${token}`
        },
      }

      const response = await axios(option);

      if (response) {

        return response.data.response;
      }

    } catch (error) {

      window.alert('이전 정보를 불러오는데 실패했어요.');
    }

  } else {

    window.location.replace('*');
  }
}

export const checkId = async function (props) { /** 크리에이터 존재 여부 확인 api */

  try {

    await axios.get(`/creators/${props.creatorId}`, {
      params: {
        part: "open",
      },
    });

    return true;

  } catch (e) { // 없는 아이디

    props.callBack();
  }
}

export const checkSponsorBusiness = async function (props) {  /** 사업자인증 api */
  const { businessNum: businessNum, businessName: businessName, businessSince: businessSince, successCallback: successCallback, errorCallback: errorCallback } = props;

  const options = {
    url: `/sponsors/${businessNum}/authentications/VERIFY_BUSINESS`,
    method: 'POST',
    data: {
      business_number: businessNum,
      ceo_name: businessName,
      start_date: businessSince
    }
  }

  try {

    const response = await axios(options);

    if (response) {

      successCallback(response);
    }

  } catch (error) {

    console.log(error);
    errorCallback();
  }

}

/* 마이페이지 관련 */
export const patchMark = async function (avatarId, contractId, token, state) {  /** 북마크 업데이트 api */
  try {
    
    let options = {
      url: state === 'read' ? `/tr0/${contractId}/commands?creator_id=${avatarId}` :  `/tr0/${contractId}/commands?creator_id=${avatarId}`,
      method: 'POST',
      data: {
        name: state
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const response = await axios(options);

    return response

  } catch (error) {

    console.log(error);
  }
}

export const patchRead = async function (avatarId, contractId, token) { /** 읽음 업데이트 api */

  try {
    let options = {
      url: `/contracts/${contractId}/commands?creator_id=${avatarId}`,
      method: 'POST',
      data: {
        name: 'read'
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const response = await axios(options);

    return response

  } catch (error) {

    console.log(error);
  }
}

export const getCampaignDataTr0 = async function(avatarId, token, campaignType){ /** 캠페인 정보 fetch api */

    try {

      let options = {
        url: `/tr0/?scope=public&category=${campaignType}&structure=meta&creator_id=${avatarId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      const response = await axios(options);

      return response;

    } catch (error) {

      throw error.response.status
    }
}

export const getSingleCampaignDataTr0 = async function(avatarId, token, contractId){
  try{
    const options = {
      url: `/tr0/${contractId}?structure=meta&creator_id=${avatarId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const response = await axios(options);

    return response

  }catch(error){

  }
}