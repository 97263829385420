//React
import styled from 'styled-components';

//Css
import {
  Image,
  SizedBox,
  Blue,
  MainTitle,
  Subscription,
  ComponentDiv,
  ComponentContainer,
  SubPageIconBox,
  SubPageIcon
} from 'pages/LandingPage/styles';

//Assets
import icon from 'assets/LandingPageDev/subPage/AnalysisPage/section01/icon.png';
import report from 'assets/LandingPageDev/subPage/AnalysisPage/section01/report.png';
import logo from 'assets/LandingPageDev/subPage/AnalysisPage/section01/logo.png';

function Section01({ whetherDesktop }) {

  return (
    <ComponentDiv whetherBackground={true}>

      <ComponentContainer center={true}>

        <SizedBox height={whetherDesktop ? '180px' : '120px'} />

        <SubPageIconBox >
          <SubPageIcon src={icon} opacity='0.6'/>
          데이터 분석
        </SubPageIconBox>

        <MainTitle flexDirection='column' center={true}>
          SNS를 성장시키고 싶은
          <Blue>크리에이터라면?</Blue>
        </MainTitle>

        <Subscription top={whetherDesktop ? '20px' : '15px'} center={true}>
          인스타그램, 유튜브 본사와 공식 파트너십을 맺은<br />
          SNS 분석 리포트를 받아보세요!
        </Subscription>

        <SizedBox height={whetherDesktop ? '60px' : '30px'} />

        

        <Image src={logo} width={whetherDesktop ? '350px' : '260px'} />

        <SizedBox height={whetherDesktop ? '80px' : '60px'} />

        <Image src={report} style={{opacity: whetherDesktop?'1':'0'}}/>

      </ComponentContainer>

      {
        !whetherDesktop
        && <ReportMobile>
          <Image src={report} />
        </ReportMobile>
      }

    </ComponentDiv>
  );

}

export default Section01;

const ReportMobile = styled.div`
  position: absolute;
  top: 420px;
  width: 100%;
`;